import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";

const FooterSliderCardWrapper = styled.div`
  height: 100%;
  padding:10px 0px 30px 0px;

  img {
    width: 145px;
    height: 145px;
    object-fit: cover;
    border-radius: 72.5px;
    border: 5px solid #FFFFFF;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .nom{
    text-align:center;
    color:white;
    font-size:25px;
    font-style:italic;
  }
  .title-block {
    text-align:center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .teaser-block {
    text-align:center;
    overflow: hidden;
    line-height: 18px;
    color:white;
    font-size:14px;
    white-space:break-spaces;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    margin: 0;
    img {
      margin: 10px;
    }
  }
`;

const FooterSliderCard = ({photo,nom,teaser}) => {
  return (
    <FooterSliderCardWrapper>
      <div className='justify-content-center'>
        <div className='col-12' style={{textAlign:"center"}} >
          <Anchor code='concierge'>
              <ImageResize src={photo} />
          </Anchor>
        </div>
        <div className='col-12 title-block'><span className="nom">{nom}</span></div>
        <div className='col-12 teaser-block'>{teaser}</div>
      </div>
    </FooterSliderCardWrapper>
  );
};

FooterSliderCard.propTypes = {
  photo: PropTypes.string.isRequired,
  nom:PropTypes.string.isRequired,
  teaser:PropTypes.string.isRequired
};

export default FooterSliderCard;
