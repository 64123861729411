import React, { useContext } from 'react';
import { ThemeContext, LanguageContext } from 'ToolboxUtils/web/context/context';
// import PropTypes from "prop-types";
import TitleAltWrapper from './title-alt.style';

const TitleAlt = props => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();

  return (
    <TitleAltWrapper theme={theme} className="title-alt"> {{
      fr: <>Au fait, on existe pour de vrai et nos conseils sont gratuits</>,
      en: <>Actually... we do exist and it's free!</>
    }[language]}
    </TitleAltWrapper>
  );
};

TitleAlt.propTypes = {};

export default TitleAlt;
