const resolvePath = (path, obj) => {
  let properties = path.split('.');
  return (properties.reduce(((prev, curr) => prev[curr]), obj));
}

export default function getText(content, path, data) {
  let text = "";
  let rawText = "";
  try {
      text = path && resolvePath(path, content);
      rawText = text;
      for (let property in data) {
      const regex = new RegExp(`{{${property}}}`, 'gi');
      text = text.replace(regex, data[property]);
      }
  } catch {
      // don't remove console.log
      console.log(`Text path ${path} not found.`);
  }
  const ret = new String(text);
  ret.rawText = rawText;
  return ret;
}