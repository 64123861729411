import styled from "styled-components";

const HandMadeWrapper = styled.div`
  font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};
  color: white;
  text-align: center;
  line-height: 20px;

  .icon {
    font-size: 40px;
    color: ${props => props.theme.color.primaryColor};
    line-height: 50px;
    vertical-align: middle;
  }
`;

export default HandMadeWrapper;
