export const transports = {
  fr: [
    {
      id: 1,
      picture: 'walk',
      text:  'à pied',
    }, {
      id: 2,
      picture: 'car',
      text: 'en voiture',
    }, {
      id: 3,
      picture: 'bike',
      text: 'à vélo',
    }
  ],
  en: [
    {
      id: 1,
      picture: 'walk',
      text: 'on foot',
    }, {
      id: 2,
      picture: 'car',
      text: 'car',
    }, {
      id: 3,
      picture: 'bike',
      text: 'bike',
    }
  ]   
};
