export default function getUrlParameters(obj) {
  const arr = [];
  for (let prop in obj) {
    if (obj[prop] === undefined) {
      continue;
    }
    if (Array.isArray(obj[prop])) {
      obj[prop].forEach(value => {
        arr.push(prop + '=' + value)
      });
    } else if (typeof obj[prop] === 'boolean'){
      arr.push(prop + '=' + (obj[prop] === true ? '1' : '0'));
    } else if (obj[prop] === Object(obj[prop])){
      arr.push(getUrlParameters(obj[prop]));
    } else {
      arr.push(prop + '=' + obj[prop]);
    }
  }
  return arr.join('&');
}
