import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const FooterWrapper = styled.footer`
  &.private {
    hr {
      border-top: 1px solid ${props => props.theme.color.primaryColor};
      margin-bottom: 0;
    }
    .logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      img {
        object-fit:contain;
        width: 170px;
        height: 45px;
        padding: 0 4px;
      }
    }
  }
  & > div {
    padding: 30px 10px;
    display: flex;
    flex-flow: column;
    align-items: center;

    & > div {
      max-width: 100%;
    }
  }
  .biennale-footer{
    padding:0
  }
  .block-ot{
    background: ${props => props.theme.color.secondaryColor};
    padding-top:21px;
    padding-bottom:35px;
    .block-ot-title{
      text-align:center;
      color:white;
      font-size:16px;
      padding-bottom:17px
    }
  }
  .team {
    background: ${props => props.theme.color.primaryColor};
    box-shadow: 5px 4px 36px #00000029;

    .title {
      text-align: center;
      color: white;
    }
    .titre-footer{
      color:white;
    }

    .button-footer {
      margin: 0 20px;
    }

    .title,
    .contact,
    .slider-footer,
    .title-alt {
      margin-bottom: 20px;
    }

    @media (min-width: ${isMobileBreakpoint}px) {
      & > div {
        & > div {
          max-width: 700px;
        }
      }
      .title,
      .contact,
      .title-alt {
        padding: 0;
      }

      .title {
        text-align: left;
      }

      .container-contact-slider-title-alt {
        display: flex;
        justify-content: space-around;
      }

      .container-slider-title-alt {
        display: flex;
        flex-flow: column;
        flex: 0 38%;
      }

      .contact {
        order: 1;
        margin-left: 20px;
      }
    }
  }

  .footer {
    background: ${props => props.theme.color.secondaryColor};
    padding-bottom: 1px;

    .hand-made {
      margin-bottom: 20px;
    }

    .legal {
      max-width: 100%;
    }
  }

  .sponsor {
    display: flex;
    padding: 10px 10px;
    margin-top: 15px;
    flex-direction: column;
    text-align: center;
    img {
      margin-top: 10px;
      height: 60px;
      object-fit: contain;
    }
  }
  .partners {
    color: white;
    display: flex;
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    padding: 30px 10px;
    flex-direction: column;
    text-align: center;
    background: ${props => props.theme.color.primaryColor};
    .pictures {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 10px;
      width: 800px;
      img {
        margin-top: 10px;
        height: 80px;
        object-fit: contain;
      }
    }
  }
  .playlist {
    padding: 0;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .block-ot{
      padding:46px 0;
      .block-ot-title{
        font-size:26px;
        padding-bottom:40px;
      }
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .white-label {
      max-width: 80%;
      margin: auto;
    }
  }
  @media (max-width: ${isMobileBreakpoint}px) {
    .footer {
      margin-bottom: 62px;
    }
    .partners .pictures {
      flex-direction: column;
      width: 100%;
    }
  }
`;

export default FooterWrapper;
