import React, { useContext } from 'react';
import { ThemeContext, LanguageContext } from 'ToolboxUtils/web/context/context';

// import PropTypes from "prop-types";
import HandMadeWrapper from "./hand-made.style";

const HandMade = props => {
  const [language] = useContext(LanguageContext).usePath();

  return (
    <HandMadeWrapper className="hand-made" {...props}>
      {{
        fr: <>100% local, fait-main (ou presque) avec{" "}<span className="icon adn adn-heart" /> par des locaux passionnés</>,
        en: <>100% local, hand-made (or nearly) with{" "} <span className="icon adn adn-heart" /> by local enthusiasts</>
      }[language]} 
    </HandMadeWrapper>
  );
};

HandMade.propTypes = {};

export default HandMade;
