import useApiCore from './use-api-core/use-api-core';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';

const useApiChuchoteurs = {
  // eslint-disable-next-line
  get: (...args) => useApiCore(apiChuchoteurs, 'get', ...args),
  // eslint-disable-next-line
  put: (...args) => useApiCore(apiChuchoteurs, 'put', ...args),
  // eslint-disable-next-line
  post: (...args) => useApiCore(apiChuchoteurs, 'post', ...args),
  // eslint-disable-next-line
  delete: (...args) => useApiCore(apiChuchoteurs, 'delete', ...args),
};

export default useApiChuchoteurs;
