import React, {useEffect} from 'react';
import { withRouter } from "react-router-dom";

const ScrollToTop = props => {
  useEffect(() => {
    if (props.doScroll) {
      window.scrollTo(0, 0)
    }
  }, [props.location, props.doScroll])

  return props.children
}

export default withRouter(ScrollToTop);
