import React, { useState, useEffect, useContext, Suspense } from 'react';
import { WebappContext, AccountContext, WhiteLabelContext, LanguageContext } from "ToolboxUtils/web/context/context";
import { useHistory } from 'react-router-dom';
import Menu from 'ToolboxComponents/webapp/layout/menu/menu';
import Banner from 'ToolboxComponents/commons/banner/banner';
import getPath from 'Utils/web/get-path';
//import ModalStartUpPopin from 'ToolboxComponents/webapp/modals/modal-startup-popin/modal-startup-popin';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';

import {transports} from 'ToolboxComponents/webapp/pages/search-view/fake-data/transports.js';


let nbGapiLoadTries = 0;
let jwtdecode = null;

const MenuWithModals = props => {
  const history = useHistory();
  const [signIn, setSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [addSecret, setAddSecret] = useContext(WebappContext).usePath('modals.addSecret');
  const [cookieModalContext, setCookieModalContext] = useContext(WebappContext).usePath('modals.cookieModal');
  const [preHomeModalContext, setPreHomeModalContext] = useContext(WebappContext).usePath('modals.ModalPreHome');
  const [StartupPopin,SetStartUpPopin]= useContext(WebappContext).usePath('modals.startUpPopin');
  const [hasStartupPopin]= useContext(WebappContext).usePath('hasStartupPopin');


  const [selectedTab, setSelectedTab] = useContext(WebappContext).usePath('selectedTab');
  const [modals] = useContext(WebappContext).usePath('modals');

  const [account, setAccount] = useContext(AccountContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [ModalHome, setModalHome] = useState(null);
  const [ModalSignIn, setModalSignIn] = useState(null);
  const [ModalSignUp, setModalSignUp] = useState(null);
  const [ModalPreferences, setModalPreferences] = useState(null);
  const [ModalForgottenPassword, setModalForgottenPassword] = useState(null);
  const [ModalAddSecret, setModalAddSecret] = useState(null);
  const [ModalCookie, setModalCookie] = useState(null);
  const [ModalPreHome, setModalPreHome] = useState(null);
  const [ModalStartUpPopin, setModalStartUpPopin] = useState(null);

  const [homeModalOpened, openHomeModal] = useState(false);
  const [signInModalOpened, openSignInModal] = useState(false);
  const [signUpModalOpened, openSignUpModal] = useState(false);
  const [preferencesModalOpened, openPreferencesModal] = useState(false);
  const [forgottenPasswordModalOpened, openForgottenPasswordModal] = useState(false);
  const [secretModalOpened, openAddSecretModal] = useState(false);
  const [cookieModalOpened, openCookieModal] = useState(false);
  const [preHomeModalOpened, openPreHomeModal] = useState(false);
  const [startUpPopinModalOpened, openStartupPopinModal] = useState(false);

  const [homeErrorMessage, setHomeErrorMessage] = useState(null);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState(null);
  const [signInErrorMessage, setSignInErrorMessage] = useState(null);
  const [forgottenPasswordErrorMessage, setForgottenPasswordErrorMessage] = useState(null);
  const [forgottenPasswordEmailSent, setForgottenPasswordEmailSent] = useState(false);
  const [profiles, setProfiles] = useState(null);
  const [token, addAccountToken] = useState(null);
  const [activated, setActivated] = useState(true);
  const [bannerTopPosition, setBannerTopPosition] = useState('0px');
  const [menuMarginBottom, setMenuMarginBottom] = useState(null);
  const [isGoogleDisplayed, setGoogleDisplay] = useState(false);
  const [isSecretSaved, setSecretSaved] = useState(false);
  const [errorOnSecretSave, setErrorOnSecretSave] = useState(false);

  useEffect(() => {
    if (token) {
      const decode = () => {
        localStorage.clear();

        const data = jwtdecode(token);
        localStorage.setItem('token', token);
  
        const favorites = apiChuchoteurs.get('/products/', {
          params: {filter: 'account-favorites', accountId: data.id, whiteLabelId: whiteLabel.id},
          token: token
        });
        let profiles = null;
        if (data.isSignUp) {
          profiles = apiChuchoteurs.get('/profiles', {
            params: {language: language, whiteLabelId: whiteLabel.id},
          });
        }
        Promise.all([favorites, profiles]).then(response => {
          data.favorites = response[0];
          const profiles = response[1];
          setAccount(data);
          if (signUpModalOpened) {
            openSignUpModal(false);
            if (!data.isSignIn) {
              openPreferencesModal(true);
            }
          } else if (preferencesModalOpened) {
            openPreferencesModal(false);
          } else if (signInModalOpened) {
            openSignInModal(false);
            if (data.isSignUp) {
              setProfiles(profiles);
              openPreferencesModal(true);
            }
          } else if (homeModalOpened) {
            openHomeModal(false);
            if (!data.isSignIn) {
              openPreferencesModal(true);
            }
          }
        }).catch(err => {
          apiChuchoteurs.post(`/logs`, {
            body: {error: {...err, type: 'front'}}
          })
        })
      }

      if (jwtdecode === null) {
        import(/* webpackChunkName: "account" */ 'jwt-decode').then(x => {
          jwtdecode = x.default;
          decode();
        });
      } else {
        decode();
      }
    }
  }, [token]);
  useEffect(() => {
    if (signUpErrorMessage) {
      setTimeout(function(){
        setSignUpErrorMessage(null);
      }, 8000);
    }
  }, [signUpErrorMessage]);
  useEffect(() => {
    if (homeErrorMessage) {
      setTimeout(function(){
        setHomeErrorMessage(null);
      }, 8000);
    }
  }, [homeErrorMessage]);
  useEffect(() => {
    if (forgottenPasswordErrorMessage) {
      setTimeout(function(){
        setForgottenPasswordErrorMessage(null);
      }, 8000);
    }
  }, [forgottenPasswordErrorMessage]);
  useEffect(() => {
    if (account && activated === true && account.isActivated === false) {
      setActivated(false);
    }
    if (account === undefined || (account && account.isActivated === true)) {
      setActivated(true);
    }
  }, [account]);
  useEffect(() => {
    if (activated === false) {
      setTimeout(function(){
        setBannerTopPosition(`${document.getElementById('main-nav').offsetHeight}px`);
        setMenuMarginBottom(`${document.getElementById('isNotActivated').offsetHeight}px`);
      }, 100);
      setTimeout(function(){
        setActivated(true);
      }, 10000);
    }
  }, [activated]);
  useEffect(() => {
    if (forgottenPasswordModalOpened === false) {
      setForgottenPasswordEmailSent(false);
    }
  }, [forgottenPasswordModalOpened]);
  useEffect(() => {
    if (modals.signIn.opened) {
      openSignInModal(true);
    }
  }, [modals.signIn]);
  useEffect(() => {
    if (!signInModalOpened) {
      setSignIn({opened: false, from: null});
    }
  }, [signInModalOpened]);
  useEffect(() => {
    if (errorOnSecretSave) {
      setTimeout(function(){
        setErrorOnSecretSave(false);
      }, 6000);
    }
  }, [errorOnSecretSave]);
  useEffect(() => {
    if (!secretModalOpened) {
      setSecretSaved(false);
    }
  }, [secretModalOpened]);
  useEffect(() => {
    loadGapi();
  }, []);
  useEffect(() => {
    if (modals.addSecret) {
      openAddSecretModal(true);
    }
  }, [modals.addSecret]);
  useEffect(() => {
    if (!secretModalOpened) {
      setAddSecret(false);
    }
  }, [secretModalOpened]);
  useEffect(() => {
    if (cookieModalContext) {
      openCookieModal(true);
    }
  }, [cookieModalContext]);

  useEffect(() => {
    if (preHomeModalContext) {
      openPreHomeModal(true);
    }
  }, [preHomeModalContext]);

  useEffect(() => {
    if (StartupPopin) {
        openStartupPopinModal(true);
    }
  }, [StartupPopin]);


  useEffect(() => {
    if (homeModalOpened && ModalHome === null) {
      setModalHome(React.lazy(() => import(/* webpackChunkName: "account" */'ToolboxComponents/webapp/modals/modal-home/modal-home')));
    }
  }, [homeModalOpened]);

  useEffect(()=>{
    if(whiteLabel.config.hasStartupPopin === true){
      if(startUpPopinModalOpened && ModalStartUpPopin === null) {
        setModalStartUpPopin(React.lazy(() => import('ToolboxComponents/webapp/modals/modal-startup-popin/modal-startup-popin'))); 
      } 
    }
     
  },[startUpPopinModalOpened]);
  
  useEffect(() => {
    if (signInModalOpened && ModalSignIn === null) {
      setModalSignIn(React.lazy(() => import(/* webpackChunkName: "account" */'ToolboxComponents/webapp/modals/modal-signin/modal-signin')));
    }
  }, [signInModalOpened]);
  
  useEffect(() => {
    if (signUpModalOpened && ModalSignUp === null) {
      setModalSignUp(React.lazy(() => import(/* webpackChunkName: "account" */'ToolboxComponents/webapp/modals/modal-signup/modal-signup')));
    }
  }, [signUpModalOpened]);
  
  useEffect(() => {
    if (preferencesModalOpened && ModalPreferences === null) {
      setModalPreferences(React.lazy(() => import(/* webpackChunkName: "account" */'ToolboxComponents/webapp/modals/modal-preferences/modal-preferences')));
    }
  }, [preferencesModalOpened]);
  
  useEffect(() => {
    if (forgottenPasswordModalOpened && ModalForgottenPassword === null) {
      setModalForgottenPassword(React.lazy(() => import(/* webpackChunkName: "account" */'ToolboxComponents/webapp/modals/modal-forgotten-password/modal-forgotten-password')));
    }
  }, [forgottenPasswordModalOpened]);
  
  useEffect(() => {
    if (secretModalOpened && ModalAddSecret === null) {
      setModalAddSecret(React.lazy(() => import('ToolboxComponents/webapp/modals/modal-secret/modal-secret')));
    }
  }, [secretModalOpened]);


  useEffect(() => {
    if (cookieModalOpened && ModalCookie === null) {
      setModalCookie(React.lazy(() => import('ToolboxComponents/webapp/modals/modal-cookie/modal-cookie')));
    }
  }, [cookieModalOpened]);

  // useEffect(() => {
  //   if (preHomeModalOpened && ModalPreHome === null) {
  //     setModalCookie(React.lazy(() => import('ToolboxComponents/webapp/modals/modal-pre-home/modal-pre-home')));
  //   }
  // }, [preHomeModalOpened]);

  const loadGapi = () => {
    if (window.gapi) {
      setGoogleDisplay(true);
      window.gapi.load('auth2', () => {
        if(!window.gapi.auth2.getAuthInstance()) {
          window.gapi.auth2.init({client_id: process.env.CONFIG.GOOGLE});
        }
      })
    } else {
      if (nbGapiLoadTries < 60) { // 30s - autrement un adblock est en place
        window.setTimeout(function() {
          nbGapiLoadTries++;
          loadGapi();
        }, 500);
      }
    }
  }
  const verifyOpeningAddSecretModal = () => {
    if (account) {
      openAddSecretModal(true);
    } else {
      setSignIn({opened: true, from: 'secret'});
    }
  }

  const verifyOpeningGoToFavorites = () => {
    if (account) {
      setSelectedTab('favorites');
      const accountPath = whiteLabel.pages.find(page => page.code === 'account').path;
      history.push(getPath('account', whiteLabel, language));
    } else {
      setSignIn({opened: true, from: 'favorites'});
    }
  }
  const verifyOpeningGoToSecrets = () => {
    setSelectedTab('secrets');
    const accountPath = whiteLabel.pages.find(page => page.code === 'account').path;
    history.push(getPath('account', whiteLabel, language));
  };
  const verifyOpeningGoToProfile = () => {
    setSelectedTab('profile');
    const accountPath = whiteLabel.pages.find(page => page.code === 'account').path;
    history.push(getPath('account', whiteLabel, language));
  };

  /*****   Facebook   *****/

  const signUpWithFacebook = async (authResponse) => {
    try {
      const newAccountToken = await apiChuchoteurs.post('/accounts/facebook', {
        body: {token: authResponse.accessToken, userID: authResponse.userID, whiteLabelId: whiteLabel.id}
      });
      addAccountToken(newAccountToken);
      const profiles = await apiChuchoteurs.get('/profiles', {
        params: {language: language, whiteLabelId: whiteLabel.id},
      });
      setProfiles(profiles);
    } catch (err) {
      if (err.status === 400) {
        setHomeErrorMessage(whiteLabel.textualContent.modal.home.share);
        setSignUpErrorMessage(whiteLabel.textualContent.modal.home.share);
      }
      if (err.status === 404) {
        setHomeErrorMessage(whiteLabel.textualContent.modal.home.signupBefore);
        setSignInErrorMessage(whiteLabel.textualContent.modal.home.signupBefore);
      }
      if (err.status === 409) {
        setHomeErrorMessage(whiteLabel.textualContent.modal.home.alreadyLinked);
        setSignInErrorMessage(whiteLabel.textualContent.modal.home.alreadyLinked);
      }
      apiChuchoteurs.post(`/logs`, {
        body: {error: {...err, type: 'front'}}
      })
    }
  }
  const signInWithFacebook = async (authResponse) => {
    try {
      const accountToken = await apiChuchoteurs.post('/accounts/facebook/signin', {
        body: {token: authResponse.accessToken, userID: authResponse.userID, whiteLabelId: whiteLabel.id}
      });
      addAccountToken(accountToken);
    } catch (err) {
      if (err.status === 400) {
        setHomeErrorMessage(whiteLabel.textualContent.modal.home.share);
        setSignUpErrorMessage(whiteLabel.textualContent.modal.home.share);
      }
      if (err.status === 404) {
        setHomeErrorMessage(whiteLabel.textualContent.modal.home.signupBefore);
        setSignInErrorMessage(whiteLabel.textualContent.modal.home.signupBefore);
      }
      apiChuchoteurs.post(`/logs`, {
        body: {error: {...err, type: 'front'}}
      })
    }
  }
  const checkFacebookSignUpState = () => {
    FB.getLoginStatus(({ status, authResponse }) => {
      if (status !== 'connected') {
        FB.login(async ({ authResponse }) => {
          signUpWithFacebook(authResponse);
        }, {scope: 'email'});
      } else if (status === 'connected') {
        signInWithFacebook(authResponse);
      }
    });
  }
  const checkFacebookSignInState = () => {
    FB.getLoginStatus(({ status, authResponse }) => {
      if (status !== 'connected') {
        FB.login(async ({ authResponse }) => {
          signInWithFacebook(authResponse);
        }, {scope: 'email'});
      } else if (status === 'connected') {
        signInWithFacebook(authResponse);
      }
    });
  }

  /***** Fin Facebook *****/

  /*****   Google   ******/

  const signUpWithGoogle = async (token) => {
    try {
      const newAccountToken = await apiChuchoteurs.post('/accounts/google', {
        body: {token: token, whiteLabelId: whiteLabel.id}
      });
      addAccountToken(newAccountToken);
      const profiles = await apiChuchoteurs.get('/profiles', {
        params: {language: language, whiteLabelId: whiteLabel.id},
      });
      setProfiles(profiles);
    } catch (err) {
      if (err.status === 403 || err.status === 409) {
        setHomeErrorMessage(whiteLabel.textualContent.modal.home.alreadyLinked);
        setSignInErrorMessage(whiteLabel.textualContent.modal.home.alreadyLinked);
      }
      apiChuchoteurs.post(`/logs`, {
        body: {error: {...err, type: 'front'}}
      })
    }
  }
  const signInWithGoogle = async (token) => {
    try {
      const accountToken = await apiChuchoteurs.post('/accounts/google/signin', {
        body: {token: token, whiteLabelId: whiteLabel.id}
      });
      addAccountToken(accountToken);
    } catch (err) {
      if (err.status === 403 || err.status === 409) {
        setHomeErrorMessage(whiteLabel.textualContent.modal.home.alreadyLinked);
        setSignInErrorMessage(whiteLabel.textualContent.modal.home.alreadyLinked);
      }
      apiChuchoteurs.post(`/logs`, {
        body: {error: {...err, type: 'front'}}
      })
    }
  }
  const checkGoogleSignUpState = () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    if(auth2.isSignedIn.get()){
      signInWithGoogle(auth2.currentUser.get().getAuthResponse().id_token);
    } else {
      auth2.signIn().then(res => {
        if(auth2.isSignedIn.get()){
          signUpWithGoogle(res.getAuthResponse().id_token);
        }
      });
    }
  }
  const checkGoogleSignInState = () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    if(auth2.isSignedIn.get()){
      signInWithGoogle(auth2.currentUser.get().getAuthResponse().id_token);
    } else {
      auth2.signIn().then(res => {
        if(auth2.isSignedIn.get()){
          signInWithGoogle(res.getAuthResponse().id_token);
        }
      });
    }
  }

  /***** Fin Google ******/

  /***** startupPopin ******/

  useEffect(() => {

  }, []);

  const saveTextualContent = async () => {
    try {
      const savedTextualContent = await apiChuchoteurs.put(`/white-labels/${whiteLabel.id}/texts`, {
        body: {textualContent: whiteLabel.textualContent, language},
        token: localStorage.token
      });
      if (savedTextualContent) {
        alert('Sauvegarde réussie');
      }
    } catch (err) {
      apiChuchoteurs.post(`/logs`, {
        body: {error: {...err, type: 'front'}}
      })
    }
  }
  return (
    <Suspense fallback={<div></div>}>
      <Menu
        selectedItem={props.selectedItem}
        isFixed={props.isFixed}
        onLogin={() => openHomeModal(true)}
        onCreateSecret={() => verifyOpeningAddSecretModal()}
        openFavorites={() => verifyOpeningGoToFavorites()}
        openSecrets={() => verifyOpeningGoToSecrets()}
        openProfile={() => verifyOpeningGoToProfile()}
        saveTextualContent={() => saveTextualContent()}
      />
      {menuMarginBottom &&
        <div style={{height: menuMarginBottom, width: '100%', marginTop: `-${menuMarginBottom}`}}></div>
      }
      {activated === false &&
        <Banner
          background='darkorange'
          text={`Votre compte n'est pas activé, veuillez vérifier vos mails`}
          isFixed={true}
          top={bannerTopPosition}
          onClose={() => setActivated(true)}
          bannerName='isNotActivated'
        />
      }
      {homeModalOpened && ModalHome && 
        <ModalHome
          closeModal={() => openHomeModal(false)}
          openSignUpModal={() => {openHomeModal(false), openSignUpModal(true)}}
          openSignInModal={() => {openHomeModal(false), openSignInModal(true)}}
          signUpWithFacebook={() => checkFacebookSignUpState()}
          signUpWithGoogle={() => checkGoogleSignUpState()}
          homeErrorMessage={homeErrorMessage}
          isGoogleDisplayed={isGoogleDisplayed}
        />
      }
      {signInModalOpened && ModalSignIn &&
        <ModalSignIn
          closeModal={() => openSignInModal(false)}
          signIn={async (data) => {
            try {
              const accountToken = await apiChuchoteurs.post('/accounts/signin', {
                body: {email: data.email, password: data.password, whiteLabelId: whiteLabel.id}
              });
              addAccountToken(accountToken);
            } catch (err) {
              setSignInErrorMessage(whiteLabel.textualContent.modal.signIn.wrongLogin);
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          openForgottenPasswordModal={() => {openSignInModal(false), openForgottenPasswordModal(true)}}
          openSignUpModal={() => {openSignInModal(false), openSignUpModal(true)}}
          signInWithFacebook={() => checkFacebookSignInState()}
          signInWithGoogle={() => checkGoogleSignInState()}
          signInErrorMessage={signInErrorMessage}
          alternativeText={modals.signIn.from}
          isGoogleDisplayed={isGoogleDisplayed}
        />
      }
      {signUpModalOpened && ModalSignUp &&
        <ModalSignUp
          closeModal={() => openSignUpModal(false)}
          signUp={async (data) => {
            try {
              const newAccountToken = await apiChuchoteurs.post('/accounts', {
                body: {firstName: data.firstName, email: data.email, password: data.password, whiteLabelId: whiteLabel.id}
              });
              addAccountToken(newAccountToken);
              const profiles = await apiChuchoteurs.get('/profiles', {
                params: {language: language, whiteLabelId: whiteLabel.id},
              });
              setProfiles(profiles);
            } catch (err) {
              setSignUpErrorMessage(whiteLabel.textualContent.modal.signUp.alreadyExisting);
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          openSignInModal={() => {openSignUpModal(false), openSignInModal(true)}}
          signUpWithFacebook={() => checkFacebookSignUpState()}
          signUpWithGoogle={() => checkGoogleSignUpState()}
          signUpErrorMessage={signUpErrorMessage}
          isGoogleDisplayed={isGoogleDisplayed}
        />
      }
      {preferencesModalOpened && profiles && ModalPreferences &&
        <ModalPreferences
          closeModal={() => openPreferencesModal(false)}
          transports={transports[language]}
          profiles={profiles}
          setPreferences={async (data) => {
            try {
              const accountUpdatedToken = await apiChuchoteurs.put(`/accounts/${account.id}`, {
                body: {transportId: data.transportId, profileId: data.profileId, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              addAccountToken(accountUpdatedToken);
            } catch (err) {
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
        />
      }
      {forgottenPasswordModalOpened && ModalForgottenPassword &&
        <ModalForgottenPassword
          closeModal={() => openForgottenPasswordModal(false)}
          sendForgottenPasswordEmail={async (data) => {
            try {
              const mailSent = await apiChuchoteurs.put(`/accounts/email`, {
                body: {email: data.email, whiteLabelId: whiteLabel.id, language: language}
              })
              setForgottenPasswordEmailSent(true);
            } catch (err) {
              if (err.status === 403) {
                setForgottenPasswordErrorMessage(whiteLabel.textualContent.modal.forgottenPassword.noInit);
              } else {
                setForgottenPasswordErrorMessage(whiteLabel.textualContent.modal.forgottenPassword.noAccount2);
              }
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          openSignInModal={() => {openForgottenPasswordModal(false), openSignInModal(true)}}
          forgottenPasswordErrorMessage={forgottenPasswordErrorMessage}
          emailSent={forgottenPasswordEmailSent}
        />
      }
      {secretModalOpened && ModalAddSecret &&
        <ModalAddSecret
          onSubmit={async (object) => {
            try {
              const createdSecret = await apiChuchoteurs.post(`/secrets/`, {
                body: {accountId: account.id, ...object.secret, whiteLabelId: whiteLabel.id, language: language},
                token: localStorage.token
              })
              setSecretSaved(true);
              updateSecretsList({secret: createdSecret, update: false});
            } catch (err) {
              setErrorOnSecretSave(true);
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...err, type: 'front'}}
              })
            }
          }}
          isSecretSaved={isSecretSaved}
          errorOnSecretSave={errorOnSecretSave}
          onClose={() => openAddSecretModal(false)}
        />
      }
      {cookieModalOpened && ModalCookie &&
        <ModalCookie
          onClose={() => {
            openCookieModal(false);
            setCookieModalContext(false);
          }}
        /> 
      }
      {startUpPopinModalOpened && ModalStartUpPopin && hasStartupPopin &&
        <ModalStartUpPopin
          onClose={() => {
            openStartupPopinModal(false); 
            SetStartUpPopin(false);
            document.cookie = "chuchoteurs" + window.location.hostname + "Popin=1;expires=Fri, 31 Dec 2100 23:59:59 GMT; path=/";
          }}
        />
      }
    </Suspense>
  );
}

export default MenuWithModals;
