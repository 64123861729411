// TODO s'il  ya plusieurs types de cookies à gérer :
// Chaque type est représenté par un numéro 
// 0 : unset 
// 1 : autorisé
// 2 : refusé
// Les types sont concaténés :
// Exemple : 12101
// Tant qu'il y a un 0 dans la chaine, alors l'utilisateur n'a pas terminé son choix.


export const COOKIE_UNSET = "0";
export const COOKIE_STATS_DENIED = "1";
export const COOKIE_STATS_ALLOWED = "2";