import React, { useState, useContext } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import { LanguageContext, ThemeContext, WhiteLabelContext, WebappContext } from 'ToolboxUtils/web/context/context';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import { mdiWindows } from "@mdi/js";



const LanguagesWrapper = styled.div.attrs({ className: "select" })`
.dropdown-toggle {
  background-color:${props => props.theme.color.primaryColor};
  border: none;
  color: #fff;
  font-size: 0.88rem;
  line-height: 1em;
  padding: 9px 12px;
  text-transform: uppercase;
  outline: none;
}

 .dropdown-menu {
  border: 1px solid ${props => props.theme.color.primaryColor};
  border-radius: 0;
  min-width: auto;
  padding: 0;
  width: 100%;
}

 .dropdown-item {
  color: ${props => props.theme.color.primaryColor};
  font-size: 0.88rem;
  padding: 5px 10px;
  outline: none;
}

 .dropdown-item.active,
 .dropdown-item:hover {
  color: ${props => props.theme.color.primaryColor};
  background-color: inherit;
  font-weight: 600;
}

 .dropdown-item.active:after,
 .dropdown-item:hover:after {
  font-family: 'agitateurs' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\\ea39";
  font-size: 0.8rem;
  font-weight: normal;
  margin-left: 0.5em;
}
 
`;

function swap(page, url, oldLanguage, newLanguage) {
  const path = url.substr(window.location.origin.length);
  return window.location.origin + path.replace(page.path[oldLanguage], page.path[newLanguage]);  
}

function reload(oldLanguage, newLanguage, currentPage) {
  document.cookie = "chuchoteurs" + window.location.hostname + "Language=" + newLanguage + ";expires=Fri, 31 Dec 2100 23:59:59 GMT; path=/";
  if (currentPage !== undefined) {
    window.location.href = swap(currentPage, window.location.href, oldLanguage, newLanguage);
  } else {
    window.location.reload();
  }
}

const SelectLanguages = props => {
  const theme = useContext(ThemeContext).state; 
  const whiteLabel = useContext(WhiteLabelContext).state; 
  const [toggle, setToggle] = useState(false);
  const [language, setLanguage] = useContext(LanguageContext).usePath();
  const [currentPage] = useContext(WebappContext).usePath('currentPage');
  const { className } = props;

  return (
    <LanguagesWrapper className={className} theme={theme} style={{marginRight:'0'}}>
      <div className="dropdown">
        <button onClick={() => setToggle(!toggle)} className="dropdown-toggle" type="button" id="dropdownMenuButton" style={{height: '40px', textTransform: 'uppercase'}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{language}</button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{display: toggle? 'block':'none'}}>
          {
            props.languages.map(lang => 
              <button type="button" key={"selectLanguage" + lang} onClick={() => {reload(language, lang, currentPage)}} className={"dropdown-item" + (language === lang ? ' active' : '')}>{lang.toUpperCase()}</button>             
            )
          }
        </div>
      </div>

    </LanguagesWrapper>
  );
};

export default SelectLanguages;
