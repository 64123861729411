import React, { useState, useEffect, useContext, Suspense } from 'react';
import {Helmet} from "react-helmet";
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { AccountContext, GeolocationContext, WebappContext, WhiteLabelContext, LanguageContext } from "ToolboxUtils/web/context/context";
import Menu from 'App/root/menu-with-modals/menu-with-modals';
import Footer from 'ToolboxComponents/webapp/layout/footer/footer';
import Content from 'ToolboxComponents/webapp/layout/content/content';
import Body from 'ToolboxComponents/webapp/layout/body/body';
import ScrollToTop from 'ToolboxUtils/web/components/scroll-to-top';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import getMetaDescription from 'ToolboxUtils/web/get-meta-description';
import {showZopim, hideZopim} from 'ToolboxUtils/web/chat/zopim';
import routes from './routes';
import ReactPixel from 'react-facebook-pixel';
import GA4React from "ga-4-react";
import NotFound from 'App/root/not-found/not-found';

let MyMenu = undefined;
let MyFooter = undefined;
let paths= {};
let whiteLabelRoutes = {};

const Page = props => {
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const [account] = useContext(AccountContext).usePath();
  const [addSecret, setAddSecret] = useContext(WebappContext).usePath('modals.addSecret');
  const [cookieModal, setCookieModal] = useContext(WebappContext).usePath('modals.cookieModal');
  const [preHomeModal, setPreHomeModal] = useContext(WebappContext).usePath('modals.ModalPreHome');
  const [signIn, setSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [currentPage, setCurrentPage] = useContext(WebappContext).usePath('currentPage');
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language, setLanguage] = useContext(LanguageContext).usePath();
  const [isContentLoaded, setIsContentLoaded] = useContext(WebappContext).usePath('isContentLoaded');
  const [geolocation] = useContext(GeolocationContext).usePath();
  const [isFixedHeader, setFixedHeader] = useState(undefined);
  const [scrollToTop, setScrollToTop] = useState(undefined);
  const [pages, setPages] = useState();
  const [page, setPage] = useState(undefined);
  const [pageName, setPageName] = useState(props.match.params.page || '/');
  const [isPageChanged, setPageChanged] = useState(false);
  const [experts, setExperts] = useState([]);
  const [concierge, setConcierge] = useState();
  const [concierges, setConcierges] = useState();
  const [componentsUpdated, updateComponents] = useState(false); // force le render pour le MyMenu/MyFooter
  

  useEffect(() => {
    if (whiteLabel.id) {
      (async () => {
        try {
          const experts = await apiChuchoteurs.get('/accounts/experts', {
            params: {limit: 4, language, whiteLabelId: whiteLabel.id, filter: 'footer'}
          });
          setExperts(experts);
        } catch (err) {
          apiChuchoteurs.post(`/logs`, {
            body: {error: {...err, type: 'front'}}
          })
        }
      })();
    }
    const homepages = [];
    const lang = props.routeLang || 'fr';
    if (whiteLabel.pages) {
      paths = {};
      for (let prop in routes) {
        const page = whiteLabel.pages.find(page => page.code === routes[prop].code);
        if (!page) {
          continue;
        }
        const path = page.path;
        const route = {
          ...routes[prop],
          path: path[lang],
          fullRoute: path[lang] + routes[prop].route,
          lang,
          page
        };
        whiteLabelRoutes[prop] = route;
        paths[path[lang]] = route;
        if (page.code === 'home') {
          homepages.push(route);
        }
      }
      setPages([
        ...Object.values(whiteLabelRoutes).filter(x => x.code !== 'home').map(({ fullRoute, page, lang, Component }) =>
          <Route
            path={'*' + fullRoute}
            key={fullRoute + lang}
            render={props => {
              setCurrentPage(page);
              const myProps = {...props, page};
              return <Component {...myProps}/>
            }}
          />)
        ,
        ...homepages.map(homepage =>
        <Route
          exact path={(whiteLabel.path ? '/' + whiteLabel.path : '') + homepage.fullRoute}
          key={homepage.fullRoute}
          render={props => {
            const page = homepage.page;
            setCurrentPage(page);
            const myProps = {...props, page};
            return <homepage.Component {...myProps} />
          }}
        />)
        , <Route
            path='*'
            key="notFound"
            render={props => {
              return <NotFound/>
            }}
          />
       ]);
      setPageChanged(true);
    }
  },[whiteLabel]);

  useEffect(() => {
    if (geolocation && geolocation.coordinates) {
      const { longitude, latitude } = geolocation.coordinates;
      (async () => {
        try {
          const concierge = await apiChuchoteurs.get('/concierges', {
            params: {filter: 'closest', longitude: longitude, latitude: latitude, language, isInZone: whiteLabel.isInZone, whiteLabelId: whiteLabel.id}
          });
          setConcierge(concierge[0]);
          setConcierges(concierge.slice(1,4));
        } catch (err) {
          apiChuchoteurs.post(`/logs`, {
            body: {error: {...err, type: 'front'}}
          })
        }
      })();
    }
  },[geolocation, whiteLabel]);


  useEffect(() => {
    if (isPageChanged) {
      const pathElements = pageName.split('/');
      let route = whiteLabelRoutes.home;
      while (pathElements.length > 0) {
        const routeTemp = paths['/' + pathElements.join('/')];
        if (routeTemp !== undefined) {
          route = routeTemp;
          break;
        }
        pathElements.pop();
      }
      setPage(route);
      if (whiteLabel && whiteLabel.config) {
        if (whiteLabel.config.facebookPixelId) {
          ReactPixel.pageView();
        }
        if ((whiteLabel.config.gaId && GA4React.isInitialized()) || whiteLabel.config.matomo) {
          // a timeout so that the pageview is send after the meta title ise set by helmet
          // TODO : use Helmet prop : <Helmet onChangeClientState={onHelmetChangeCallback}> on each page to send pageview
          setTimeout(() => {
            analytics.pageview(window.location.pathname + window.location.search);
          }, 1500);
          //ReactGA.pageview(window.location.pathname + window.location.search);
        }
      }
      setPageChanged(false);
    }
  },[isPageChanged]);

  useEffect(() => {
    if (page !== undefined) {
      setFixedHeader(page.isFixedHeader);
      setScrollToTop(page.scrollToTop ? page.scrollToTop : true);
      MyMenu = page.menu === undefined ? Menu : page.menu;
      MyFooter = page.footer === undefined ? Footer : page.footer;
      updateComponents(!componentsUpdated);
      if (["search"].includes(page.code)) {
        hideZopim();
      } else {
        showZopim();
      }
    }
  },[page]);


  useEffect(() => {
    if (props.match.params.page !== pageName) {
      setPageName(props.match.params.page || '/');
      setIsContentLoaded(false);
      setPageChanged(true);
    }
  },[window.location.href]);

  const verifyOpeningAddSecretModal = () => {
    if (account) {
      setAddSecret(true);
    } else {
      setSignIn({opened: true, from: 'secret'});
    }
  }
  return (
    pageName !== undefined && MyMenu !== undefined && MyFooter !== undefined &&
    <ScrollToTop doScroll={scrollToTop}>
      <Helmet>
        <title>{`${(page.page.metaTitle && page.page.metaTitle[language]) || ((page.name[language] || page.name['fr']) + ' - ' + whiteLabel.name)}`}</title>
        {page.page.metaDescription && <meta name="description" content={getMetaDescription(page.page.metaDescription[language])} />}

        {['account', 'public', 'results'].includes(page.code) &&
           <meta name="robots" content="noindex" />
        }

        {page.route === '' && <link rel="canonical" href={'https://' + window.location.hostname + (whiteLabel.path ? '/' + whiteLabel.path : '') + page.path} />}
      </Helmet>
      <Body>
        {MyMenu && <MyMenu selectedItem={pageName} isFixed={!!isFixedHeader} />}
        <Content
          openPreHomeModal={() => {setPreHomeModal(true)}}
          pageCode={page.code}
          verifyOpeningAddSecretModal={verifyOpeningAddSecretModal}
          openCookieModal={() => {setCookieModal(true)}}
        >
          <Switch>
            <Suspense fallback={<div></div>}>
              {pages}
            </Suspense>
          </Switch>
        </Content>
        {isContentLoaded && MyFooter && <MyFooter experts={experts} concierge={concierge} concierges={concierges} generalBlocks={whiteLabel.generalBlocks} pictures={whiteLabel.pictures} playlists={whiteLabel.playlists} />}
      </Body>
    </ScrollToTop>
  );
};

export default withRouter(Page);
