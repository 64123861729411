const ADDRESS_FIEDS = ['name', 'placeName', 'address1', 'address2', 'address3', 'address4', 'postalCode', 'city'];

export default function formatAddress ({data, options}) {
  let address = '';
  let emptyAddress = true;
  for(const field of ADDRESS_FIEDS){
    if(data[field]){
      if (!emptyAddress && field === 'postalCode') {
        address += options.secondBreak;
      }
      if (!emptyAddress) {
        address += ' ';
      }
      address += data[field].trim();
      if (field === 'name' || field === 'placeName') {
        address += options.firstBreak || ' <br />';
      }
      emptyAddress = false;
    }
  }
  return address;
}
