import styled from 'styled-components';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';

const TitleAltWrapper = styled.div`
  font-family: ${props => props.theme.font.secondaryFont.name};
  text-transform: uppercase;
  font-size: ${props => `${(17 - isMobileView())*props.theme.font.secondaryFont.scaling/100}px`};
  font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)};
  text-align: center;
`;

export default TitleAltWrapper;
