import styled from 'styled-components';

const BannerWrapper = styled.div`
  display: flex;
  background: ${props => { return props.background }};
  color: white;
  text-align: center;
  font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  justify-content: center;
  align-items: center;
  padding: 5px 36px;
  width: 100%;
  position: ${props => (props.isFixed === true ? 'fixed' : '')};
  top: ${props => (props.isFixed === true ? props.top : '')};
  z-index: 4;
  button {
    position: fixed;
    right: 0;
    border: 0;
    background: none;
    color: white;
    font-size: 1.4rem;
  }
`;

export {BannerWrapper};
