import React, { useRef, useEffect, useState, useContext } from 'react';
import { AccountContext, ThemeContext, WebappContext, WhiteLabelContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import { NavWrapper } from 'ToolboxComponents/webapp/layout/menu/menu.styles';
import Account from 'ToolboxComponents/webapp/layout/menu/components/account/account';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Login from 'ToolboxComponents/webapp/layout/menu/components/login';
import PropTypes from 'prop-types';
import SelectLanguages from 'ToolboxComponents/webapp/layout/menu/components/select-languages';

const Menu = ({ selectedItem, onSelectLanguage, isFixed, onLogin, onCreateSecret, openFavorites, openSecrets, openProfile, saveTextualContent }) => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const [activated, setActivated] = useContext(WebappContext).usePath('edition.activated');
  const [resultsView, setResultsView] = useContext(WebappContext).usePath('resultsView');
  const [search, setSearch] = useContext(WebappContext).usePath('search');


  const [bannerHeight] = useContext(WebappContext).usePath('layout.bannerHeight');
  const [highlighted, setHighlighted] = useContext(WebappContext).usePath('edition.highlighted');
  const [stateAccount, setStateAccount] = useContext(AccountContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [, setForceUpdateCount] = useState(null);
  const [account, setAccount] = useState(stateAccount ? stateAccount : undefined);
  const [isBodyClicked, setBodyClicked] = useState(false);
  const [isMobile, setIsMobile] = useState(null);
  const [menuTravelHeight, setMenuTravelHeight] = useState();

  const isMenuTravel = useRef(true);

  const resetSearch = item => {
    if (item.code==='search') {
      setSearch(null);
      setResultsView({
        selectedSlideId: null,
        activeProducts: null,
      })
    }
  }

  useEffect(() => {
    if (bannerHeight !== null) {
      setMenuTravelHeight(window.pageYOffset + bannerHeight);
    } else {
      setMenuTravelHeight(undefined);
    }
  }, [bannerHeight]);
  useEffect(() => {
    if (!isMobile) {
      const onScrollTravel = () => {
        const pageY = window.pageYOffset;
        const currentMenuTravel = isMenuTravel.current;

        if (pageY >= menuTravelHeight && bannerHeight !== 0) {
          isMenuTravel.current = false;
          if (currentMenuTravel === true) {
            forceUpdate();
          }
        } else {
          isMenuTravel.current = true;
          if (currentMenuTravel === false) {
            forceUpdate();
          }
        }
      };

      document.addEventListener('scroll', onScrollTravel);

      return () => document.removeEventListener('scroll', onScrollTravel);
    }
  }, [bannerHeight, menuTravelHeight, isMobile]);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  useEffect(() => {
    setAccount(stateAccount);
  }, [stateAccount]);

  const forceUpdate = () => {
    setForceUpdateCount(isMenuTravel.current);
  };

  return (
    <NavWrapper
      id='main-nav'
      isMobile={isMobile}
      theme={theme}
      isMenuTravel={isMenuTravel.current}
      menuTravelHeight={menuTravelHeight}
      bannerHeight={bannerHeight}
      isFixed={isFixed}
      languages={whiteLabel.menu.languages}
    >
      <div className='container'>
        {isMobile === true && whiteLabel.menu.languages.length > 1 &&
          <SelectLanguages className='select-languages' languages={whiteLabel.menu.languages} onSelectLanguage={language => onSelectLanguage(language)}/>
        }
        <Anchor code='home' className={theme.style.logo || 'logo'}>
          <img src={whiteLabel.pictures.logo} alt='logo' />
        </Anchor>

        {whiteLabel.config.hasDoubleLogo &&
          <Anchor code='home' className={(theme.style.logo || 'logo') + ' logo2'}>
            <img src={whiteLabel.pictures.logo2} alt='logo' />
          </Anchor>
        }
        {isMobile !== true && whiteLabel.menu.languages.length > 1 &&
          <SelectLanguages className='select-languages' languages={whiteLabel.menu.languages} onSelectLanguage={language => onSelectLanguage(language)} />
        }
        {isMobile !== true &&
          <div className='nav-items'>
            {whiteLabel.menu.desktop.filter(x => !(language !== 'fr' && x.code === 'whisper')).map(item =>
              item.code === 'whisper'
              ? <div key={item.id} className='item' onClick={() => onCreateSecret()}>
                  <span className={`adn adn-${item.picture} icon`} />
                  <div className='title'>{item.title}</div>
                </div>
              : item.code === 'favorites'
                ? <div key={item.id} className='item' onClick={() => openFavorites()}>
                    <span className={`adn adn-${item.picture} icon`} />
                    <div className='title'>{item.title}</div>
                  </div>
                : <Anchor key={item.id} code={item.code} callback={() => resetSearch(item)} className={`item ${selectedItem === item.id ? 'selected' : ''}`}>
                    <span className={`adn adn-${item.picture} icon`} />
                    <div className='title'>{item.title}</div>
                  </Anchor>
            )}
          </div>
        }
        {language === "fr" && whiteLabel.config.hasAccount !== false &&
        <>
          {(account === undefined || account === null )
            ? <Login isMobile={isMobile} onLogin={() => onLogin()} theme={theme} />
            : <Account
                account={account}
                isMobile={isMobile}
                theme={theme}
                setBodyClicked={setBodyClicked}
                openProfile={openProfile}
                openSecrets={openSecrets}
                openFavorites={openFavorites}
                onCreateSecret={onCreateSecret}
                setStateAccount={setStateAccount}
                setActivated={setActivated}
                setHighlighted={setHighlighted}
                saveTextualContent={saveTextualContent}
                activated={activated}
                highlighted={highlighted}
              />
          }
          </>
        }
        {isMobile === true &&
          <nav id='mobile-nav'>
            {whiteLabel.menu.mobile.map(item =>
              item.code === 'favorites'
              ? <button key={item.id} className='item' onClick={() => openFavorites()}>
                  <span className={`adn adn-${item.picture} icon`} />
                  <div className='title'>{item.title}</div>
                </button>
              : <Anchor key={item.id} code={item.code} className={`item ${selectedItem === item.id ? 'selected' : ''}`}>
                  <span className={`adn adn-${item.picture} icon`} />
                  <div className='title'>{item.title}</div>
                </Anchor>
            )}
          </nav>
        }
      </div>
    </NavWrapper>
  );
};

Menu.propTypes = {
  isFixed: PropTypes.bool,
  onCreateSecret: PropTypes.func,
  onLogin: PropTypes.func,
  onSelectLanguage: PropTypes.func, //call back when user selects language
  openFavorites: PropTypes.func,
  openProfile: PropTypes.func,
  openSecrets: PropTypes.func,
  saveTextualContent: PropTypes.func,
  selectedItem: PropTypes.string, //what menu item should be highlighted
};

export default Menu;
