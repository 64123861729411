import React from "react";
// import PropTypes from "prop-types";
import ButtonFooterWrapper from "./button-footer.style";
import Text from 'ToolboxComponents/commons/texts/text/text';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";

const ButtonFooter = ({ theme }) => {
  return (
    <ButtonFooterWrapper className="button-footer" theme={theme}>
      <Anchor className="link" code='concierge'>
        <Text path={`page.concierge.expertButtonLink`} />
      </Anchor>
    </ButtonFooterWrapper>
  );
};

ButtonFooter.propTypes = {};

export default ButtonFooter;
