import {useState, useEffect} from 'react';

const usePromise = (promise, options) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    promise()
      .then(data => {
        setData(data);
        setIsLoading(false);
        setIsComplete(true);
        if (options && options.onComplete) {
          options.onComplete({data, isLoading: false, isComplete: true, isError: false});
        }
      })
      .catch(error => {
        setIsError(true);
        setIsLoading(false);
        setIsComplete(true);
        setError(error);
        if (options && options.onComplete) {
          options.onComplete({error, isLoading: false, isComplete: true, isError: true});
        }
        // TODO: send error to datawarehouse
        console.log(error);
      })
  }, [options && options.depends]);

  return {
    data,
    isLoading,
    isComplete,
    isError,
    error
  };
}

export default usePromise;
