import React, { useContext, useState } from 'react';
import { WhiteLabelContext, LanguageContext, WebappContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import { AnchorWrapper } from "ToolboxComponents/commons/anchors/anchor/anchor.styles";

const Anchor = ({ children, code, callback, oldPath, pageParams, urlParams, ...rest }) => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [, setUrlParams] = useContext(WebappContext).usePath('urlParams');
  const [language] = useContext(LanguageContext).usePath();

  let path = whiteLabel.pages.find(page => page.code === code).path[language];
  if (pageParams) {
    path += pageParams;
  }
  if (whiteLabel.path !== null) {
    path = '/' + whiteLabel.path + path;
  }
  
  return <AnchorWrapper to={path} onClick={() => {setUrlParams({[code]: urlParams}); if (callback) {callback()};}} {...rest}>{children}</AnchorWrapper>;
};

Anchor.propTypes = {
  code: PropTypes.string,
  path: PropTypes.string,
  pageParams: PropTypes.string
};

export default Anchor;
