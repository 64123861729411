import React, { useContext } from 'react';
import { WebappContext } from "ToolboxUtils/web/context/context";
import Text from 'ToolboxComponents/commons/texts/text/text';

import ContactWrapper from './contact.style';
import addHttps from 'ToolboxUtils/web/formats/add-https';
import formatAddress from 'ToolboxUtils/web/formats/address';
import DirectionsLink from 'ToolboxComponents/commons/links/directions-link/directions-link';

const Contact = props => {
  const [analytics] = useContext(WebappContext).usePath('analytics');

  const { concierge } = props;
  const {
    phone,
    sms,
    chat,
    messenger,
    mail,
    name,
    address1,
    address2,
    postalCode,
    city,
  } = concierge;

  const address = formatAddress({
    data: concierge,
    options: { firstBreak: '<br />', secondBreak: ',' },
  });

  return (
    <ContactWrapper className="contact" {...props}>
      {sms !== undefined && sms !== null && sms !== '' && (
        <a href={`sms:${concierge.sms}`} className="block" onClick={() => {analytics.sendSmsContactEvent(concierge)}}
        >
          <div className="icon adn adn-message-processing"></div>
          <Text
              className="info"
              path={`page.concierge.sidebarText`}
              data={{ phone: `${concierge.sms}`}}
          />
        </a>
      )}
      {chat !== undefined && chat !== null && chat !== '' && (
        <a href={addHttps(concierge.chat)} target={`_blank`} className="block" onClick={() => {analytics.sendChatContactEvent(concierge)}}>
          <div className="icon adn adn-forum"></div>
          <Text
              className="info"
              path={`page.concierge.sidebarChat`}
          />
        </a>
      )}
      {messenger !== undefined && messenger !== null && messenger !== '' && (
        <a
          href={addHttps(concierge.messenger)}
          target="_blank"
          className="block"
          onClick={() => {analytics.sendMessengerContactEvent(concierge)}}
        >
          <div className="icon adn adn-facebook-messenger"></div>
          <Text
            className="info"
            path={`page.concierge.sidebarMessenger`}
          />
        </a>
      )}
      {mail !== undefined && mail !== null && mail !== '' && (
        <a href={`mailto:${concierge.mail}`} className="block"  onClick={() => {analytics.sendMailContactEvent(concierge)}}>
          <div className="icon adn adn-email"></div>
          <Text
              className="info"
              path={`page.concierge.sidebarEmail`}
          />
        </a>
      )}
      {phone !== undefined && phone !== null && phone !== '' && (
        <a href={`tel:${concierge.phone}`} className="block"  onClick={() => {analytics.sendPhoneContactEvent(concierge)}}>
          <div className="icon adn adn-phone"></div>
          <Text
              className="info"
              path={`page.concierge.sidebarPhone`}
              data={{ phone: `${concierge.phone}`}}
          />
        </a>
      )}
      {address1 !== undefined && address1 !== null && address1 !== '' && (
        <DirectionsLink className={`block`} product={concierge}  onClick={() => {analytics.sendAddressContactEvent(concierge)}}>
          <div className="icon adn adn-store"></div>
          <div className="info address">
            <Text
                className="info"
                path={`page.concierge.sidebarAddress`}
                data={{ address: ``}}
            />
            <div dangerouslySetInnerHTML={{ __html: address }} />
          </div>
        </DirectionsLink>
      )}
    </ContactWrapper>
  );
};

Contact.propTypes = {};

export default Contact;
