//imports
import React from 'react';
import imageWidthCalculate from 'ToolboxComponents/utils/images/image-resize/image-width-calculate';
import getAltImage from 'ToolboxUtils/web/get-alt-image';

//import the apiServerUrl from config file
const apiServerUrl = process.env.CONFIG.API_IMAGES;

//main component
const ImageResize = ({ src, width, alt = '', className = '', style = null }) => {
  //determine what size of image will be served
  const calculatedWidth = imageWidthCalculate(width);

  //functions
  const getImageUrl = () => {
    //force component to use original image?
    if (width === 'original') {
      return src;
    } else {
      //get resized image from API server
      return `${apiServerUrl}?url=${src}&width=${calculatedWidth}`;
    }
  };

  //variable to handle 404 from server
  let errorFlag = false;

  return (
    <>
      {src ? (
        <img
          src={getImageUrl()}
          className={className}
          style={style}
          //if getImageUrl sends error, will load original image instead
          onError={e => {
            if (!errorFlag) {
              e.target.src = src;
              errorFlag = true;
            }
          }}
          alt={getAltImage(alt)}
        />
      ) : (
        <img alt={getAltImage(alt)} />
      )}
    </>
  );
};

export default ImageResize;
