import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext, LanguageContext } from "ToolboxUtils/web/context/context";
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-pre-home/modal-pre-home.styles';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';

const ModalPreHome = props => {
    const theme = useContext(ThemeContext).state;
    const language = useContext(LanguageContext).state;
    
    return (
      <ModalWrapper theme={theme} isMobile={isMobileView()} >
        <div className='modal-container'>
          <div className='container'>
          <button onClick={() => props.onClose()} className='close' type='button'>
            <i className='icon adn adn-close' />
          </button>
             <div className='description-container'>
              <div className='container description'>
                <div className='Whitelabel-logo'></div>
                <p>On a la chance de vivre ici toute l'année et on vous partage nos spots</p>
                <div className= 'row icons'>
                  <div className='col-4'>
                    <i className='icon adn adn-local-play'/>
                    <p><span>DES SPOTS LOCAUX</span> Argument numéro 1, sur 2 lignes comme ici autour des spots locaux</p>
                  </div>
                  <div className='col-4'>
                    <i className='icon adn adn-open-people'/>
                    <p><span>CHUCHOTÉS PAR DES LOCAUX</span> Argument numéro 1, sur 2 lignes comme ici autour des spots locaux</p>
                    </div>
                  <div className='col-4'>
                    <i className='icon adn adn-open-people'/>
                    <p><span>LOCAL ET ENGAGÉ</span>Argument numéro 1, sur 2 lignes comme ici autour des spots locaux</p>
                  </div>
                </div>
                <div className='texte-fermeture'>
                <i className='icon adn adn-arrow-circle-up'/><span id="text-close">C'est très clair, on peut fermer cet écran :)</span> <i className='icon adn adn-arrow-circle-up'/>
                </div>
              </div>
          </div> 
          </div>
        </div>
      </ModalWrapper>
    );
  }
  
  ModalPreHome.propTypes = {
    closeModal: PropTypes.func.isRequired,
  };
  
  export default ModalPreHome;
  