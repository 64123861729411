import React, { useEffect, useState, useContext } from 'react';
import { AccountWrapper } from 'ToolboxComponents/webapp/layout/menu/components/account/account.styles';
import { WhiteLabelContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import Avatar from 'ToolboxAssets/img/default-photo.png';
import PropTypes from 'prop-types';

const Account = props => {
  const { account,
    isMobile,
    theme,
    setBodyClicked,
    openProfile,
    openSecrets,
    openFavorites,
    onCreateSecret,
    setStateAccount,
    setActivated,
    setHighlighted,
    saveTextualContent,
    activated,
    highlighted
  } = props;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();

  const [accountClickTime, setAccountClickTime] = useState(null);
  const [isAccountMenuDisplayed, showAccountMenu] = useState(false);
  const [hello] = useState({
    fr: 'Bonjour',
    en: 'Hello'
  }[language])

  const handleAccountMenu = (e) => {
    showAccountMenu(false);
    setBodyClicked(true);
    document.body.removeEventListener('click', handleAccountMenu);
    setAccountClickTime(e.timeStamp);
  }
  const handleAccountClick = (e) => {
    if (!isAccountMenuDisplayed) {
      if (accountClickTime !== e.timeStamp) {
        document.body.addEventListener('click', handleAccountMenu);
        showAccountMenu(true);
      } else {
        setBodyClicked(false)
      }
    }
  };
  return (
      <AccountWrapper isMobile={isMobile} theme={theme} className={{transform: isAccountMenuDisplayed ? 'shadow' : ''}}>
        <div onClick={(e) => handleAccountClick(e)}>
          <div className='account-block'>
            <div className='text'>{hello} <br/> {account.firstName}</div>
            <img className='picture' src={account.photo ? account.photo : Avatar} alt='User Account' />
            <span className='adn adn-chevron-down icon'></span>
          </div>
          <div className='account-drop-menu'  style={{transform: isAccountMenuDisplayed ? ' scaleY(1)' : ' scaleY(0)'}}>
            <ul className='userSettings'>
              <li onClick={() => openProfile()}>
                {whiteLabel.textualContent.page.menu.account}
              </li>
              {language === 'fr' &&  whiteLabel.id !== 10 && whiteLabel.id !== 24  && <li onClick={() => openSecrets()}>
                {whiteLabel.textualContent.page.menu.secrets}
              </li>}
              <li onClick={() => openFavorites()}>
                {whiteLabel.textualContent.page.menu.favs}
              </li>
              <li onClick={() => (localStorage.clear(), setStateAccount(undefined))}>
                {whiteLabel.textualContent.page.menu.logout}
              </li>
            </ul>
            {((account && account.status === 'admin') || (account && account.status === 'superAdmin')) &&
                <div className='adminSettings'>
                  <li className='backoffice' onClick={() => (window.open(`/admin`))}>
                    <span className='adn adn-dashboard icon'></span>
                    {/*{whiteLabel.textualContent.page.menu.backoffice}*/}
                    <p>accès back office</p>
                  </li>
                  <div className='textedit'>
              <span className={activated && highlighted ? 'adn adn-flash_on icon' : 'adn adn-power icon'}
                    onClick={() => {
                      setActivated(!activated)
                      setHighlighted(!highlighted)
                    }}></span>
                    <p>{activated && highlighted ? 'édition textes activée' : 'édition textes désactivée'}</p>
                  </div>
                  <div className='btn-save' onClick={() => saveTextualContent()}>Sauvegarder</div>
                </div >

              /*          <li className='dropdown-title'>
                            <p>Éditions des textes</p>
                          </li>
                          <li className='setting'>
                            <input className='switch' type='checkbox' checked={activated} onClick={() => setActivated(!activated)} onChange={() => setActivated(!activated)} />
                            <div className='text'>Activé</div>
                          </li>
                          <li className='setting'>
                            <input className='switch' type='checkbox' checked={highlighted} onClick={() => setHighlighted(!highlighted)} onChange={() => setHighlighted(!highlighted)} />
                            <div className='text'>Surlignage</div>
                          </li>
                          <li className='setting' onClick={() => saveTextualContent()}>
                            <span className='adn adn-check-bold save' />
                            <div className='text'>Sauvegarder</div>
                          </li>*/
            }
          </div>
        </div>

      </AccountWrapper>
  );
};

Account.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  setBodyClicked: PropTypes.func.isRequired,
  openProfile: PropTypes.func.isRequired,
  openSecrets: PropTypes.func.isRequired,
  openFavorites: PropTypes.func.isRequired,
  onCreateSecret: PropTypes.func.isRequired,
  setStateAccount: PropTypes.func.isRequired,
  setActivated: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  saveTextualContent: PropTypes.func.isRequired,
  activated: PropTypes.bool.isRequired,
  highlighted: PropTypes.bool.isRequired,
};

export default Account;
