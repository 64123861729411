import React from "react";

const AdminContext = React.createContext();
const ThemeContext = React.createContext();
const GeolocationContext = React.createContext();
const LanguageContext = React.createContext();
const WhiteLabelContext = React.createContext();
const WebappContext = React.createContext();
const AccountContext = React.createContext();

AdminContext.displayName = "Admin";
ThemeContext.displayName = "Theme";
GeolocationContext.displayName = "Geolocation";
LanguageContext.displayName = "Language";
WhiteLabelContext.displayName = "WhiteLabel";
WebappContext.displayName = "Webapp";
AccountContext.displayName = "Account";

export {AdminContext};
export {ThemeContext};
export {GeolocationContext};
export {LanguageContext};
export {WhiteLabelContext};
export {WebappContext};
export {AccountContext};
