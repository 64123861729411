import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300000;
  overflow: auto;
  outline: 0;
  .modal-container {
    width: 100%;
    height: 50%;
    color:white;
    margin: 0;
    position: relative;
    background: #404040;
    padding-top : 1px;
    padding-bottom: 1px;
    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #ccc;
      margin: 1em 0;
      padding: 0;
    }
    .close {
      display: flex;
      color: ${props => { return props.theme.color.primaryColor }};
      opacity: 1;
      position: absolute;
      top: .5rem;
      right: .5rem;
    }
    .container {
      height: 100%;
      .description-container {
        height: 100%;
      }
      .description {
        padding: 20px ;
        font-size: ${props => `${15*props.theme.font.primaryFont.scaling/100}px`};
        margin-bottom: 2rem;
        text-align:center;
        overflow: auto;
        height: 100%;
        .icons{
          .adn{
            color:#00A49B;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #01A7A7;
            width: 140px;
            height: 140px;    
            border-radius:50%;
            font-size:100px;
          }
        }
        .texte-fermeture{
          text-align:center;
          justify-content: center;
          border: 2px dashed #FFFFFF;
          border-radius: 30px;
          .adn-arrow-circle-up{
            width:47px;
            height:47px;
            font-size:30px;
          }
        }
      }
      .button {
        display: flex;
        margin-bottom: 1.5rem;
      }
    }
  }
  @media (max-width: 767px) {
    .modal-container {
      width: 90%;
      height: 90%;
      .container {
        .description : {
          font-size: 13px;
        }
      }
    }
  }
`;