import React, { useContext } from 'react';
import { LanguageContext, ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import WhiteLabelWrapper from './white-label.style';
import PlayList from 'ToolboxComponents/webapp/layout/footer/components/playlist/playlist';
import smileToLife from 'ToolboxAssets/img/smiletolife.jpg';
import qualityLogo from 'ToolboxAssets/img/logo-qt.png';


const WhiteLabel = () => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  return (
    <WhiteLabelWrapper theme={theme}>
      <div className="white-label">
        {whiteLabel.playlist !== undefined &&
          (whiteLabel.playlist.spotify !== null ||
            whiteLabel.playlist.deezer !== null ||
            whiteLabel.playlist.youtube !== null) && (
            <PlayList playlist={whiteLabel.playlist} />
          )}
          { (whiteLabel.id === 10 || whiteLabel.id === 24)  ? 
            <></>
           : <div className="catch-phrase"> 
              <Text path='page.footer.whiteLabelText' data={{ name: whiteLabel.name }} />
            </div>
          
          }
          <div className="identity">
          <div className="address">
            <a href={whiteLabel.flagshipUrl[language]} target="_blank">
              <img className='logo' src={whiteLabel.pictures.flagshipLogo} />
            </a>
              { (whiteLabel.id === 10 || whiteLabel.id === 24)  ?
                  <a href='/' target="_blank">
                      <img className='logo' src={whiteLabel.pictures.logoPartenaire} />
                  </a>
                  : <></>
              }
          </div>
         </div>
         {whiteLabel.config && whiteLabel.config.isQualityTourism &&
            <div className='quality'>
              <img className="qualityLogo" src={qualityLogo} />
            </div>
          }
      </div>
    </WhiteLabelWrapper>
  );
};

export default WhiteLabel;
