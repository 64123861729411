import isMobileView from '../is-mobile-view';

export function toggleZopim() {
  if (isMobileView()) {
    window.$zopim.livechat.window.show();
  } else {
    document.body.classList.toggle("zopim-hide");
  }
}

export function showZopim() {
  document.body.classList.remove("zopim-hide");
}

export function hideZopim() {
  document.body.classList.add("zopim-hide");
}
