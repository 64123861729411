import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext,LanguageContext,WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import FooterWrapper from './footer.style';

import Title from './components/title/title';
import Contact from './components/contact/contact';
import SliderFooterConcierge from 'ToolboxComponents/commons/sliders/slider-footer-concierge/slider-footer-concierge';
import SliderFooter from 'ToolboxComponents/commons/sliders/slider-footer/slider-footer';
import TitleAlt from './components/title-alt/title-alt';
import ButtonFooter from 'ToolboxComponents/commons/buttons/button-footer/button-footer';
import WhiteLabel from './components/white-label/white-label';
import HandMade from './components/hand-made/hand-made';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Playlist from './components/playlist/playlist';

// import Legal from './components/legal/legal';
import SliderLegal from 'ToolboxComponents/commons/sliders/slider-legal/slider-legal';
import logoNA from 'ToolboxAssets/img/logoNA.png';
import HomeBlockBiennale from 'ToolboxComponents/webapp/pages/home-view/components/home-biennale/home-biennale';


const Footer = ({ experts, concierge, concierges, generalBlocks, pictures, playlists }) => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const legal = [
    { code: 'legals', text: {fr:'Mentions légales', en:'Legal notices'}},
    { code: 'cgu', text: {fr:'CGU Données personnelles', en:'TCU Personal data' }},
    { code: 'cgv', text: {fr:'CGV Données personnelles', en:'TCS Personal data' }},
    { code: 'privacy', text: {fr:'Politiques de confidentialité', en:'Confidentiality policy' }},
    { code: 'cookies', text: {fr:'Politique de cookies', en:'Cookies policy' }},
  ];
  const blockComponents = {
    8:  <div className='sponsor'>
          <Text path='page.footer.tina' />
          <img src={logoNA} />
        </div>,
    9:  <div className='partners'>
          <Text path='page.footer.partners' />
          {pictures.partners && pictures.partners.length > 0 &&
            <div className='pictures'>
              {pictures.partners.map(img => (
                <img src={img} />
              ))}
            </div>
          }
        </div>,
  }
  if (!concierge) {
    return null;
  } 
  
  if (whiteLabel && whiteLabel.privatePartnerId) {
    return (
      <FooterWrapper className={`footer private`} theme={theme}>
        <div className='block-ot'>
          <div className='block-ot-title'>
            <Text path={`page.footer.blockOtTitle`} />
          </div>  
          {concierges && <SliderFooterConcierge theme={theme} concierges={concierges}/>}
        </div>
        <div className="logos">
          <img src={whiteLabel.pictures.logo} alt='logo' />
          <img src={whiteLabel.pictures.logo2} alt='logo' />
        </div>
        <div className="footer">
          <HandMade theme={theme} />
          {/* <Legal /> */}
          <SliderLegal legal={legal} />
        </div>
      </FooterWrapper>
    )
  } else {
    return (
      <FooterWrapper className={`footer`} theme={theme}>
        <div className="team">
          <Title theme={theme} displayedSchedule={concierge.displayedSchedule}/>
          <div className="container-contact-slider-title-alt">
            {concierge && <Contact theme={theme} concierge={concierge} />}
            {experts.length > 0 &&
            <div className="container-slider-title-alt">
              <SliderFooter experts={experts} />
             {/* <TitleAlt /> */ } 
            </div>
            }
          </div>
          <ButtonFooter theme={theme} />
        </div>
        < div className='biennale-footer'>
          {/*generalBlocks && generalBlocks.includes(14) &&  <HomeBlockBiennale />*/}
        </div>
        <div className='block-ot'>
          <div className='block-ot-title'>
            <Text path={`page.footer.blockOtTitle`} />
          </div>  
          {concierges && <SliderFooterConcierge theme={theme} concierges={concierges}/>}
        </div>
        <div className="white-label">
          <WhiteLabel />
        </div>
        <div className="playlist">
          {generalBlocks && generalBlocks.includes(13) && <Playlist playlists={playlists}/>}
        </div>
        {generalBlocks && generalBlocks.map((x, index) => (
          <React.Fragment key={index}>{blockComponents[x]}</React.Fragment>
        ))}
        <div className="footer">
          <HandMade theme={theme} />
          {/* <Legal /> */}
          <SliderLegal legal={legal} />
        </div>
      </FooterWrapper>
    );
  } 
};

Footer.propTypes = {
  experts: PropTypes.array.isRequired,
  pictures: PropTypes.object.isRequired,
};

export default Footer;
