export default function timeoutPromise({delay, error, timeoutIdWrapper = {}, promises}) {
  let timeout = new Promise((resolve, reject) => {
    timeoutIdWrapper.id = setTimeout(() => {
      reject(error)
    }, delay)
  });

  return Promise.race([
    ...promises,
    timeout
  ]).then(result => {
    clearTimeout(timeoutIdWrapper.id)
    return result;
  }).catch(error => {
    clearTimeout(timeoutIdWrapper.id)
    throw error;
  });
}
