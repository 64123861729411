import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import imageBifurcations from 'ToolboxAssets/img/Signes_bifurcations.png';



const HomeBlockBiennaleWrapper = styled.div`
background: black url(${imageBifurcations}) 0% 0% no-repeat padding-box;
opacity: 80%;
width:100%;
height: 450px;
  .block-biennale{
      display: flex;
      flex-direction:column;
      text-align: center;
      color:white;
      position:relative;
      margin: 52px 0 32px 0;
      .logo-biennale{
        padding-bottom: 80px;
        img{
          max-width:300px;
        }
      }
      .link-programmation-biennale{
          font-size: 25px;
          color:white;
          white-space:nowrap;
      }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .block-biennale{
      .logo-biennale{
        img{
          max-width:400px;
        }
      }
      .link-programmation-biennale{
        font-size: 40px;
      }
    }  
  }

`;

export default HomeBlockBiennaleWrapper;