import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import styled from 'styled-components';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const SliderLegalWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;

  .swiper-slide {
    width: auto !important;
    padding: 0 20px;
  }

  .separator {
    color: #aaa;
  }

  a {
    color: #aaa;
    transition: color 0.1s;

    &:hover {
      color: #c7c7c7;
      text-decoration: none;
    }
  }
`;

const SliderLegal = ({ legal }) => {
  const [language] = useContext(LanguageContext).usePath();
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  return (
    <SliderLegalWrapper className="slider-footer">
      {isMobile === true && (
        <div className="mobile">
          <Slider
            key={'footer-slider'}
            mobile={{
              // slidesPerView: 1.2,
              slidesPerView: 'auto',
              // freeMode: true,
              slidesPadding: 10,
              groupSliding: false,
            }}
          >
            {legal.map((info, index) => (
              <Anchor key={index} code={info.code}>
                {info.text[language]}
              </Anchor>
            ))}
          </Slider>
        </div>
      )}
      {isMobile === false && (
        <div className="desktop">
          {legal.map((info, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && <span className="separator"> | </span>}
                <Anchor code={info.code}>
                  {info.text[language]}
                </Anchor>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </SliderLegalWrapper>
  );
};

SliderLegal.propTypes = {
  legal: PropTypes.array.isRequired,
};

export default SliderLegal;
