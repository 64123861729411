import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'App/root/root';
import 'es7-object-polyfill';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import ContextProvider from 'ToolboxUtils/web/context/context-provider';
import { WebappContext, ThemeContext, GeolocationContext, LanguageContext, WhiteLabelContext, AccountContext} from "ToolboxUtils/web/context/context";
import IsMobileViewWrapper from 'ToolboxUtils/web/components/is-mobile-view';
import "ToolboxAssets/css/style.css";
import webappContext from './context/webapp-context.js';
import whiteLabelContext from './context/white-label-context.js';
import languageContext from './context/language-context.js';
import geolocationContext from './context/geolocation-context.js';
import accountContext from './context/account-context.js';
import themeContext from './context/theme-context';
import { debugContextDevtool } from 'react-context-devtool';
import './polyfill';

window.onerror = function (error) {
  console.error(error);
};

window.onunhandledrejection = function (error) {
  console.error(error);
};


function render() {
  ReactDOM.render(
    <ContextProvider context={GeolocationContext} value={geolocationContext}>
      <ContextProvider context={LanguageContext} value={languageContext}>
        <ContextProvider context={WhiteLabelContext} value={whiteLabelContext}>
          <ContextProvider context={ThemeContext} value={themeContext}>
            <ContextProvider context={AccountContext} value={accountContext}>
              <ContextProvider context={WebappContext} value={webappContext}>
                <IsMobileViewWrapper />
                  <AppContainer>
                    <Router>
                      <Route path="/" component={Root} />
                    </Router>
                  </AppContainer>
                </ContextProvider>
              </ContextProvider>
            </ContextProvider>
          </ContextProvider>
        </ContextProvider>
     </ContextProvider>,
    document.getElementById('app')
  );
}

try {
  render();
} catch(error) {
  console.error(error);
}



if (module.hot) {
  module.hot.accept();
}

debugContextDevtool(document.getElementById('app'));
