import React, { useState, useEffect, useContext } from 'react';
import {Helmet} from "react-helmet";
import {Switch, Route} from 'react-router-dom';
import { ThemeContext, LanguageContext, WebappContext, GeolocationContext, WhiteLabelContext, AccountContext } from "ToolboxUtils/web/context/context";
import {COOKIE_STATS_ALLOWED} from 'ToolboxParams/cookies';
import getLanguage from 'ToolboxUtils/web/cookie/get-language';
import usePromise from 'ToolboxUtils/web/hooks/use-promise';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import makeAnalytics from 'ToolboxUtils/web/analytics/make-analytics';
import getCurrentPosition from 'Utils/web/geolocation/get-current-position';
import Page from './page/page';
import ReactPixel from 'react-facebook-pixel';
import GA4React from "ga-4-react";

//needed when geolocation happens but setGeolocation inside the usePromise is called before the setGeolocation inside the useEffect
let localIsGeolocated = false;
let jwtdecode = null;


const Root = props => {
  const [geolocation, setGeolocation] = useContext(GeolocationContext).usePath();
  const [whiteLabel, setWhiteLabel] = useContext(WhiteLabelContext).usePath();
  const [cookieGpdr, setCookieGpdr] = useContext(WebappContext).usePath("cookieGpdr");
  const [analytics, setAnalytics] = useContext(WebappContext).usePath("analytics");
  const [theme, setTheme] = useContext(ThemeContext).usePath();
  const [language, setLanguage] = useContext(LanguageContext).usePath();
  const [account, setAccount] = useContext(AccountContext).usePath();

  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  usePromise(getCurrentPosition, {
    onComplete: async ({isError, data}) => {
      if (isError === true) {
        return;
      }
      localIsGeolocated = true;
      setGeolocation({
        coordinates: data,
        isGeolocated: true
      });
    }
  });


  // Geolocation effects
  useEffect(() => {
    // get the  white label only when coordinates change (so isGeolocated = true)
    // or when there is no geolocation at all (geolocation === undefined -> get the default white label)
    if (geolocation && geolocation.isGeolocated === false) {
      return;
    }
    (async () => {
      const coordinates = geolocation ? geolocation.coordinates : {};
      const urlPosition = window.location.href.indexOf('//');
      const url = window.location.href.substring(urlPosition + 2);
      const newWhiteLabel = await apiChuchoteurs.get('/white-labels', {
        params: {url: encodeURIComponent(url), language, ...coordinates, filter: 'webapp'},
        key: 'WHITE-LABEL-GEOLOC'
      });
      if (geolocation === undefined && localIsGeolocated === false) {
        const {latitude, longitude} = newWhiteLabel;
        setGeolocation({coordinates: {latitude, longitude}, isGeolocated: false});
      }
      // no white label or white label has changed
      if (whiteLabel === undefined ||
          newWhiteLabel.id !== whiteLabel.id ||
          newWhiteLabel.isInZone !== whiteLabel.isInZone ||
          newWhiteLabel.city.id !== whiteLabel.city.id)
      {
        setTheme(newWhiteLabel.theme);
        setWhiteLabel(newWhiteLabel);
      } else {
        // white label is the same but maybe the user is in a new city inside the white label
        setWhiteLabel('city', newWhiteLabel.city);
      }
    })();
  }, [geolocation, language]);
  

  // Whitelabel effects on account
  useEffect(() => {
    // account is linked only to a "master" white label (who has a domain name)
    // and not to sub-white labels, so an account can't change inside the same domain
    if (whiteLabel === undefined || account !== undefined) {
      return;
    }
    
    if (localStorage.token) {
      const decode = () => {
        const account = jwtdecode(localStorage.token);
        if(account && account.exp*1000 > Date.now()){
          const favorites = apiChuchoteurs.get('/products/', {
            params: {filter: 'account-favorites', accountId: account.id, whiteLabelId: whiteLabel.id},
            token: localStorage.token
          });
  
          Promise.all([favorites]).then(data => {
            account.favorites = data[0];
            setAccount(account);
          })
        } else {
          localStorage.clear();
        }
      }
      if (jwtdecode === null) {
        import(/* webpackChunkName: "account" */ 'jwt-decode').then(x => {
          jwtdecode = x.default;
          decode();
        });
      } else {
        decode();
      }
    }
  }, [whiteLabel]);


  // WhiteLabel effect on scripts
  useEffect(() => {
    if (whiteLabel && whiteLabel.config && whiteLabel.config.facebookId && scriptsLoaded === false) {
      setScriptsLoaded(true);
      const script = document.createElement('script');

      script.text = `
        window.fbAsyncInit = function() {
          FB.init({
            appId      : ${whiteLabel.config.facebookId},
            cookie     : false,
            xfbml      : true,
            version    : 'v14.0'
          });

          FB.AppEvents.logPageView();

        };

        (function(d, s, id){
           var js, fjs = d.getElementsByTagName(s)[0];
           if (d.getElementById(id)) {return;}
           js = d.createElement(s); js.id = id;
           js.src = "https://connect.facebook.net/fr_FR/sdk.js";
           fjs.parentNode.insertBefore(js, fjs);
         }(document, 'script', 'facebook-jssdk'));
      `;

      document.body.appendChild(script);
    }

    if (whiteLabel && whiteLabel.config) {
      if (whiteLabel.config.matomo) {
        if (window._mtm === undefined) {
          var _mtm = window._mtm = window._mtm || [];
          _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=whiteLabel.config.matomo; s.parentNode.insertBefore(g,s);
        }
        setAnalytics(makeAnalytics({matomo: whiteLabel.config.matomo}));
      } else {
        const ga4 = new GA4React(whiteLabel.config.gaId, {'anonymize_ip': true });
        ga4.initialize().then((ga) => {
          setAnalytics(makeAnalytics({ga, hasGtag: whiteLabel.config.hasGtag}));
        });
      }

      if (cookieGpdr === COOKIE_STATS_ALLOWED) {
        if (whiteLabel.config.facebookPixelId) {
          const advancedMatching = {};
          const options = {
            autoConfig: true,
            debug: false,
          };
          ReactPixel.init(whiteLabel.config.facebookPixelId, advancedMatching, options);
        }
      }
      // zopim
      if (whiteLabel.config.zopimId && language === 'fr') {
        window.$zopim||(function(d,s){var z=window.$zopim=function(c){z._.push(c)},$=z.s=
        d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
        _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
        $.src="https://v2.zopim.com/?" + whiteLabel.config.zopimId;z.t=+new Date;$.
        type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");
      }
    }

  }, [whiteLabel, cookieGpdr, language]);

  // WhiteLabel effect on language
  useEffect(() => {
    if (whiteLabel && !whiteLabel.menu.languages.includes(language)) {
      document.cookie = "chuchoteurs" + window.location.hostname + "Language=" + 'fr' + ";expires=Fri, 31 Dec 2100 23:59:59 GMT; path=/";
      window.location.href = window.location.origin;
    }
  }, [whiteLabel]);

  useEffect(() => {
    if (localStorage.token) {
      const decode = () => {
        try {
          const account = jwtdecode(localStorage.token);
          if (account.isActivated === false) {
            async function checkAccountActivated() {
              try {
                const accountToken = await apiChuchoteurs.get('/accounts/active', {
                  token: localStorage.token
                });
                localStorage.clear();
                const data = jwtdecode(accountToken);
                localStorage.setItem('token', accountToken);
              } catch (err) {
                console.log(err);
              }
            };
            checkAccountActivated()
          }
        } catch(err) {
          console.log(err);
        }
      }
      if (jwtdecode === null) {
        import(/* webpackChunkName: "account" */ 'jwt-decode').then(x => {
          jwtdecode = x.default;
          decode();
        });
      } else {
        decode();
      }
    }
  }, []);

  useEffect(() => {
    if (whiteLabel) { 
      if (language !== getLanguage("chuchoteurs" + window.location.hostname + "Language")) {
        document.cookie = "chuchoteurs" + window.location.hostname + "Language=" + language + ";expires=Fri, 31 Dec 2100 23:59:59 GMT; path=/";
        document.location.reload();
      }
      // gestion de la langue
      // if (whiteLabel.config && whiteLabel.config.zopimId) {
      //   $zopim(() => {
      //     $zopim.livechat.setLanguage(language);
      //   });
      // }
    }
  }, [whiteLabel, language])

  return (
    whiteLabel !== undefined &&
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" sizes="180x180" href={whiteLabel.pictures.favicon.apple} />
        <link rel="icon" type="image/png" sizes="192x192" href={whiteLabel.pictures.favicon.android192} />
        <link rel="icon" type="image/png" sizes="512x512" href={whiteLabel.pictures.favicon.android512} />
        <link rel="icon" type="image/png" sizes="32x32" href={whiteLabel.pictures.favicon.icon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={whiteLabel.pictures.favicon.icon16} />
        {whiteLabel.config && whiteLabel.config.facebookDomainVerification && <meta name="facebook-domain-verification" content={whiteLabel.config.facebookDomainVerification} /> }
      </Helmet>
      <Switch>
        {whiteLabel.menu.languages.includes('en') &&
        <Route path={'/' + (whiteLabel.path ? whiteLabel.path + '/en/' : 'en/') + ':page'} render={
          props => {
            setLanguage('en');
            return <Page routeLang='en'/>
          }
        }/>
        }
        {whiteLabel.menu.languages.includes('en') &&
        <Route exact path={'/en/' + (whiteLabel.path || '')} render={
          props => {
            setLanguage('en');
            return <Page routeLang='en'/>
          }
        }/>
        }
        <Route path={'/' + (whiteLabel.path ? whiteLabel.path + '/' : '') + ':page'} render={
          props => {
            setLanguage('fr');
            return <Page routeLang='fr'/>
          }
        }/>
        <Route exact path={'/' + (whiteLabel.path || '')} render={
          props => {
            setLanguage('fr');
            return <Page routeLang='fr'/>
          }
        }/>
      </Switch>
    </div>
  );
}


export default Root;
