import React, { useContext } from 'react';
import HomeBlockBiennaleWrapper from 'ToolboxComponents/webapp/pages/home-view/components/home-biennale/home-biennale.style';
import logoBiennale from 'ToolboxAssets/img/logoBID22-12EEW.png';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { LanguageContext } from 'ToolboxUtils/web/context/context';


const HomeBlockBiennale = props => {
  const [language] = useContext(LanguageContext).usePath();

    return (
      <HomeBlockBiennaleWrapper>
          <div className='row justify-content-center block-biennale'>
              <div className='logo-biennale'><img src={logoBiennale} /></div>
              <div><a className='link-programmation-biennale' href= {`${language === 'fr' ? 'https://www.biennale-design.com/saint-etienne/2022/' : 'https://www.biennale-design.com/saint-etienne/2022/en/'}`}><i className='icon adn adn-arrow-right-circle'/><Text path={`page.homepage.biennaleLink`} /></a></div>
          </div>
      </HomeBlockBiennaleWrapper>
    );
  };
  
  HomeBlockBiennale.propTypes = {};
  
  export default HomeBlockBiennale;