import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import NearestOt from '../../../webapp/layout/footer/components/NearestOtBlock/nearest-ot-block';

const SliderFooterWrapper = styled.div`
  .mobile {
    //height: 38vw;
  }

  .desktop {
    display: none;
  }
  div:nth-child(2),div:nth-child(3){
    .nearest-ot{
      .text-plus-proche{
        display:none;
      }
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    height: 100%;
    width:100%;
    max-width:1350px;

    .mobile {
      display: none;
    }
    .desktop {
      display: flex;
      flex-flow: wrap;
      div:nth-child(1){
        order:${props => props.concierges.length > 2 ? 2 : 1};
        .nearest-ot{
            img {
              width: 220px;
              height: 220px;
              border-radius: 110px;
            }
            .concierge-name{
                font-size:28px;
            }
            .text-plus-proche{
              display:block;
            }
            .info{
                span{
                    color: ${props => props.theme.color.tertiaryColor};
                }
            }
        }
      }
      div:nth-child(2){
        order:${props => props.concierges.length > 2 ? 1 : 2};
      }
      div:nth-child(3){
        order:3;
      }
      div:nth-child(2),div:nth-child(3){
        .nearest-ot{
          .text-plus-proche{
            display:none;
          }
        }
      }
      div:last-child{
        margin-left:auto;
        margin-right:auto;
      }
    }
  }
`;


const SliderFooterConcierge = ({concierges, theme}) => {
  return (
    <SliderFooterWrapper className="slider-footer-concierge" theme={theme} concierges={concierges}>
      <div className="mobile">
        <Slider
          mobile={{
            slidesPerView: 1,
            dots: true,
            slidesPadding: 10,
          }}
        >
          {concierges.map((concierge,index) => (
             <div className=''> <NearestOt key={index}  ot={concierge} /> </div>
            ))}
          
        </Slider>
      </div>
      <div className="desktop">
        {
          concierges.map((concierge,index) => {
            return <div className={'col-' +(concierges.length > 2 ? '4' : '6')}><NearestOt key={index} ot={concierge}  /></div>
          })
        }
        
      </div>
    </SliderFooterWrapper>
  );
};

SliderFooterConcierge.propTypes = {
    concierges: PropTypes.array.isRequired,
};


export default SliderFooterConcierge;
