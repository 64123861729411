export default async function getCurrentPositionPromise(options) {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      return reject();
    }
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({latitude: position.coords.latitude, longitude: position.coords.longitude});
      },
      positionError =>  {
        reject(positionError);
      },
      options
    );
  });
}
