import styled from 'styled-components';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';

const TitleWrapper = styled.div`
  .main {
    font-size: ${props => `${(26 - isMobileView() * 3)*props.theme.font.secondaryFont.scaling/100}px`};
    color: white;
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-transform: uppercase;
    line-height: 26px;
    margin-bottom: 12px;
    display: block;
  }
`;

export default TitleWrapper;
