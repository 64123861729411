import React, { useContext, useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { WebappContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import getText from "ToolboxUtils/web/get-text";


const Text = props => {
  const [TextEditor, setTextEditor] = useState(null);
  const [edition] = useContext(WebappContext).usePath('edition');
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [Tag] = useState(props.tag || 'span');

  const text = getText(whiteLabel.textualContent, props.path, props.data);

  useEffect(() => {
    if (edition.activated && TextEditor === null) {
      setTextEditor(React.lazy(() => import('ToolboxComponents/commons/texts/editor/editor')));
    }
  }, [edition.activated])

  return(
    edition.activated && TextEditor !== null ?
        <div onClick={(event) => {event.stopPropagation(); event.preventDefault()}}>
          <Suspense fallback={<div></div>}>
            <TextEditor
              text={text.rawText}
              path={props.path}
              highlighted={edition.highlighted}
            />
          </Suspense>
        </div>
      : <Tag className={props.className} dangerouslySetInnerHTML={{__html: text}}></Tag>
  )
};

Text.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
}

export default Text;
