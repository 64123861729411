import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, WhiteLabelContext, WebappContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import { ContentWrapper } from 'ToolboxComponents/webapp/layout/content/content.styles';
import CookieBand from 'ToolboxComponents/webapp/layout/content/components/cookie-band/cookie-band';
import ModalPreHome  from 'ToolboxComponents/webapp/modals/modal-pre-home/modal-pre-home';
import {COOKIE_UNSET, COOKIE_STATS_DENIED, COOKIE_STATS_ALLOWED} from 'ToolboxParams/cookies'; 
import isMobileView from "ToolboxUtils/web/is-mobile-view";

const getZopimHeight = () => {
  const elements = document.getElementsByClassName('zopim');
  return elements[0].offsetHeight + (elements[1] ? elements[1].offsetHeight : 0);
}

const Content = ({ children, fullContent, pageCode, verifyOpeningAddSecretModal, openCookieModal,openPreHomeModal }) => {
  const theme = useContext(ThemeContext).state;
  const language = useContext(LanguageContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [cookieGpdr] = useContext(WebappContext).usePath("cookieGpdr");


  const [isMobile, setIsMobile] = useState(null);
  const [windowScrollY, setWindowScrollY] = useState(0);
  const [isCreateSecretDisplayed, showCreateSecret] = useState(false);
  const [zopimHeight, setZopimHeight] = useState(null);


  const noSecretPages = ['prehome', 'search', 'destination'];
  const noSecretOnMobilePages = ['account', 'collection', 'results', 'whisperers', 'circuits-choice', 'circuit', 'cluster', 'clusters', 'catalog'];

  useEffect(() => {
    const notDisplayed = noSecretPages.includes(pageCode) || (isMobile && noSecretOnMobilePages.includes(pageCode));
    if (whiteLabel.config && whiteLabel.config.zopimId && language ==='fr' && isMobile ) {
      $zopim(() => {
        if (notDisplayed) {
          $zopim.livechat.button.hide();
        } else {
          $zopim.livechat.button.show();
        }
      });
    }
    showCreateSecret(!notDisplayed);
  }, [isMobile, pageCode]);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setWindowScrollY(window.scrollY);
    });
    if (whiteLabel.config && whiteLabel.config.zopimId && language ==='fr') {
      $zopim(() => {
        setTimeout(() => {setZopimHeight(getZopimHeight())}, 400);
        $zopim.livechat.window.onShow(() => {
          setZopimHeight(getZopimHeight())
        });
        $zopim.livechat.window.onHide(() => {
          setZopimHeight(getZopimHeight())
        });
      })
    }
  }, [])

  return (
    <ContentWrapper pageCode={pageCode} fullContent={fullContent} theme={theme} windowScrollY={windowScrollY} whiteLabel={whiteLabel} zopimHeight={zopimHeight}>
      <div className={!fullContent && 'content'}>{children}</div>
      {/*<ModalPreHome openPreHomeModal={openPreHomeModal} />*/}
      {  whiteLabel.id !== 10 && whiteLabel.id !== 24  && isCreateSecretDisplayed && language === 'fr' &&
        <button type='button' className='create-secret' onClick={() => verifyOpeningAddSecretModal()}><span className='icon adn adn-plus'/></button>
      }
    {cookieGpdr === COOKIE_UNSET && <CookieBand openCookieModal={openCookieModal} />}
    </ContentWrapper>
  );
};

Content.propTypes = {
  pageCode: PropTypes.string.isRequired,
  verifyOpeningAddSecretModal: PropTypes.func,
};

export default Content;
