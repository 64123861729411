import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const PlayListWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  background-color: ${props => props.theme.color.secondaryColor};

  .playlist-container {
    background-color: ${props => props.theme.color.secondaryColor};
    color: ${props => props.theme.color.primaryColor};
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 15px;
    color: white;
    text-transform: uppercase;
  }

  .playlist-container .logo {
    margin-top: -44px;
    margin-bottom: 15px;
  }

  .playlist-container .title {
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-weight: ${props =>
      parseInt(props.theme.font.secondaryFont.weight) + 300};
    font-size: ${props =>
      `${(20 * props.theme.font.secondaryFont.scaling) / 100}px`};
    text-align: center;
  }

  .playlist-container > .text {
    text-transform: none;
    color: ${props => props.theme.color.primaryColor};
  }

  .links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
  }

  .link {
    display: flex;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;

    & + .link {
      margin-left: 10px;
    }
  }

  .link .image {
    flex: 1 1 auto;

    img {
      max-width: 70px;
      min-width: 40px;
      width: 100%;
    }
  }

  .link .text {
    margin-left: 5px;
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-weight: ${props =>
      parseInt(props.theme.font.secondaryFont.weight) + 300};
    font-size: ${props =>
      `${(12 * props.theme.font.secondaryFont.scaling) / 100}px`};
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .link {
      & + .link {
        margin-left: 20px;
      }
      .text {
        margin-left: 10px;
        font-size: ${props =>
          `${(18 * props.theme.font.secondaryFont.scaling) / 100}px`};
      }
    }
  }
`;

export default PlayListWrapper;
