import styled from 'styled-components';

const ButtonLinkWrapper = styled.button`
  color: ${props => (props.switchColors === true ? 'white' : props.theme.color.primaryColor)};
  border: 0px;
  background: ${props => (props.switchColors === true ? props.theme.color.primaryColor : 'white')};
  text-decoration: underline;
`;

export {ButtonLinkWrapper};
