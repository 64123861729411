import React, { useContext, useEffect, useState } from 'react';
import {
  WebappContext,
  AccountContext,
  GeolocationContext,
} from 'ToolboxUtils/web/context/context';
// import PropTypes from "prop-types";
import directions from 'ToolboxUtils/web/maps/directions';
import travelMode from 'ToolboxUtils/web/maps/travel-mode';
import getDevicePlatform from 'ToolboxUtils/web/get-device-platform';

import styled from 'styled-components';

const DirectionsLinkWrapper = styled.div``;

const DirectionsLink = props => {
  const context = useContext(WebappContext);
  const { product } = props;
  const [account] = useContext(AccountContext).usePath();
  const [geolocation] = useContext(GeolocationContext).usePath();
  const [destinationLongitude, setDestinationLongitude] = useState(0);
  const [destinationLatitude, setDestinationLatitude] = useState(0);

  useEffect(() => {
    if (product !== undefined) {
      setDestinationLatitude(product.latitude);
      setDestinationLongitude(product.longitude);
    }
  }, [product]);

  const {
    latitude: originLatitude,
    longitude: originLongitude,
  } = geolocation.coordinates;

  const transportId = account && account.transportId;

  const platform = getDevicePlatform();
  const travel = travelMode(transportId);

  return (
    <DirectionsLinkWrapper>
      <a
        href={directions({
          service: 'google-maps',
          platform: platform,
          origin: {
            latitude: originLatitude,
            longitude: originLongitude,
          },
          destination: {
            latitude: destinationLatitude,
            longitude: destinationLongitude,
          },
          travelMode: travel,
          zoom: 15,
        })}
        target="_blank"
        rel="noopener noreferrer"
        className={props.className}
        onClick={props.onClick || null}
      >
        {props.children}
      </a>
    </DirectionsLinkWrapper>
  );
};

// DirectionsLink.propTypes = {};

export default DirectionsLink;
