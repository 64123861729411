import getUrlParameters from 'ToolboxUtils/web/get-url-parameters';

const fetchMap = new Map();

export default class Api {

  constructor(defaultUrl = '', defaultOptions = {}) {
    this.defaultUrl = defaultUrl;
    const options = {
      mode: 'cors',
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }
    };
    this.defaultOptions = {...options, ...defaultOptions};
  }

  async fetch(url, options = {}) {
    let myFetchPromise = null;
    try {
      const headers = {};
      if (options.token !== undefined) {
        headers['Authorization'] = 'Bearer ' + options.token;
      }
      const fetchOptions = {
          mode: options.mode || this.defaultOptions.mode,
          method: options.method || this.defaultOptions.method,
          headers: {...this.defaultOptions.headers, ...options.headers, ...headers}
      };
      if (options.body) {
        fetchOptions.body = JSON.stringify(options.body);
      }
      if (options.cache || this.defaultOptions.cache) {
        fetchOptions.cache = options.cache || this.defaultOptions.cache;
      }
      const params = getUrlParameters(options.params);
      const queryString = params.length > 0 ? '?' + params : '';
      const absoluteUrl = this.defaultUrl + url + queryString;
      const myFetch = async () => {
        const response = await fetch(absoluteUrl, fetchOptions);
        const contentType = response.headers.get("content-type");
        const data =
          (contentType && contentType.indexOf("application/json") !== -1) ?
          await response.json() :
          await response.text();

        if (response.ok) {
          return data;
        } else {
          throw {
            isHttpError: true,
            status: response.status,
            data,
            type: data && data.error && data.error.type,
            message: data && data.error && data.error.message
          };
        }
      };

      myFetchPromise = myFetch();

      // register to the fetch map before waiting for fetch
      if (options.key) {
        const {key} = options;
        if (!fetchMap.has(key)) {
          fetchMap.set(key, []);
        }
        const fetchArray = fetchMap.get(key);
        fetchArray.push(myFetchPromise);
      }

      // wait for my fetch
      const data = await myFetchPromise;

      //if key then activate scheduler
      if (options.key) {
        const {key} = options;
        const fetchArray = fetchMap.get(key);
        // remove my fetch
        const index = fetchArray.findIndex(x => x === myFetchPromise);
        fetchArray.splice(index, 1);
        //console.log(absoluteUrl, fetchArray);
        // if there is no other query or this was the most ancient
        if (fetchArray.length === 0 || index === 0) {
          return data;
        } else {
          // if some queries were initiated before, wait for their queries
          // get the most recent fetch and wait for it (not the most aged fetch because then young fetches may attach to the most aged fetch before older fetches)
          const mostRecentFetch = fetchArray[index - 1];
          await mostRecentFetch;
          return data;
        }
      // no key -> return data
      } else {
        return data;
      }
    } catch (err) {
      if (options.key && fetchMap.has(options.key)) {
        const fetchArray = fetchMap.get(options.key);
        fetchMap.set(options.key, fetchArray.filter(x => x === myFetchPromise));
      }
      if (err.isHttpError) {
        throw err;
      } else {
        throw {
          isHttpError: false,
          name: 'Fetch error',
          message : `Api error: URL: ${url} | PARAMS: ${JSON.stringify(options.params)}
            ERROR: ${err}
            STACK: ${err.stack}
          `
        }
      }
    }
  }

  async get(url, options) {
    const result = await this.fetch(url, {...options, method: 'get'});
    return result;
  }

  async post(url, options) {
    const result = await this.fetch(url, {...options, method: 'post'});
    return result;
  }

  async put(url, options) {
    const result = await this.fetch(url, {...options, method: 'put'});
    return result;
  }

  async delete(url, options) {
    const result = await this.fetch(url, {...options, method: 'delete'});
    return result;
  }
}
