export default function getMetaDescription(description) {
  if (!description) {
    return "";
  }
  if (description.length > 145) {
    const rawDescription = description.slice(0, 145);
    const index = rawDescription.lastIndexOf(" ");
    return rawDescription.slice(0,index).replaceAll(`"`, `'`) + ' ...';
  } 
  return description.replaceAll(`"`, `'`);
}