let isMobileViewMemo = undefined;

export default function isMobileView() {
  if (isMobileViewMemo) {
    return isMobileViewMemo;
  }
  if (document.getElementById("is-mobile-view")) {
    isMobileViewMemo = (
      getComputedStyle(document.getElementById("is-mobile-view")).visibility ===
      "visible"
    );
    return isMobileViewMemo;
  } else {
    return null;
  }
}

export { isMobileBreakpoint } from "ToolboxUtils/web/components/is-mobile-view";
