import getCookieGpdr from 'ToolboxUtils/web/cookie/get-cookie-gpdr';
import getCookiePopin from 'ToolboxUtils/web/cookie/get-cookie-popin';
import makeAnalytics from 'ToolboxUtils/web/analytics/make-analytics';

export default {
  layout: {
    bannerHeight: null,
  },
  cookieGpdr: getCookieGpdr(),
  analytics: makeAnalytics(),
  hasStartupPopin: getCookiePopin(),
  geolocation: {
    coordinates: {
      latitude: '43.6333',
      longitude: '-1.4333',
    },
    isGeolocated: true,
  },
  map: {
    markers: {
      //control used markers and custom colors
      user: {
        icon: 'user',
        color: 'theme',
      },
      global: {
        icon: 'marker',
        color: 'theme',
      },
      selected: {
        icon: 'marker-selected',
        color: 'theme',
      },
      small: {
        icon: 'mini',
        color: 'theme',
      },
    },
  },
  resultsView: {
    selectedSlideId: null,
    activeProducts: null,
  },
  product: {
    itineraryPopover: {
      width: 310,
      offset: -127,
      sectionWidth: '50%',
    },
    productRequested: false,
    fullDescription: false,
    favDone: [],
    result: {},
  },
  edition: {
    activated: false,
    highlighted: false,
  },
  modals: {
    signIn: {
      opened: false,
      from: null,
    },
    addSecret: false,
    startUpPopin: true,

  },
};
