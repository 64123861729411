const travelMode = transportId => {
  switch (transportId) {
    case 2:
      return "bicycling";
    case 3:
      return "walking";
    default:
      return "driving";
  }
};

export default travelMode;
