import React, { useContext } from 'react';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import { ButtonLinkWrapper } from './button-link.styles';

const ButtonLink = props => {
  const theme = useContext(ThemeContext).state;
  return (
    <ButtonLinkWrapper theme={theme} switchColors={props.switchColors} onClick={() => props.onClick && props.onClick()}>{props.children}</ButtonLinkWrapper>
  );
}

ButtonLink.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  switchColors: PropTypes.bool,
}

export default ButtonLink;
