import React, { useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import styled from 'styled-components';

const BodyWrapper = styled.div`
  @import url(${props =>
    `https://fonts.googleapis.com/css?family=${props.googleFonts}&display=swap`});

  ${props =>
    props.customFonts.map(
      font =>
        `@font-face {
        font-family: ${font.name};
        src: url(${font.url});
      }`,
    )}

  font-family: ${props => props.fonts.primaryFont.name}, sans-serif;
`;

const Body = props => {
  const theme = useContext(ThemeContext).state;
  const fonts = theme.font;
  const fontsValues = Object.values(fonts);

  const googleFonts = fontsValues
    .filter(font => font.url === null)
    .map(
      font =>
        font.name.replace(/\s+/g, '+') +
        (font.options !== null ? font.options : ''),
    )
    .join('|');

  const customFonts = fontsValues.filter(font => font.url !== null);

  return (
    <BodyWrapper
      fonts={fonts}
      googleFonts={googleFonts}
      customFonts={customFonts}
    >
      {props.children}
    </BodyWrapper>
  );
};

export default Body;
