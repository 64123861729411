import React, { useState, useContext } from 'react';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
// import PropTypes from 'prop-types';
import addHttps from 'ToolboxUtils/web/formats/add-https';
import PlayListWrapper from 'ToolboxComponents/webapp/layout/footer/components/playlist/playlist.style';

import Waves from 'ToolboxComponents/webapp/layout/footer/components/playlist/assets/ondes.png';
import Spotify from 'ToolboxComponents/webapp/layout/footer/components/playlist/assets/spotify.png';
import Deezer from 'ToolboxComponents/webapp/layout/footer/components/playlist/assets/deezer.png';
import Youtube from 'ToolboxComponents/webapp/layout/footer/components/playlist/assets/youtube.png';

const PlayList = props => {
  const { playlists } = props;
  const theme = useContext(ThemeContext).state;

  return (
    <PlayListWrapper className={`playlist`} theme={theme}>
      <div className="playlist-container">
        <img className="logo" src={Waves} alt="" />
        <div className="title"><Text path={`page.footer.playlistTitle`} /></div>
        <div className="text"><Text path={`page.footer.playlistSubtitle`} /></div>
        <div className="links">
          {playlists.spotify && (
            <a
              href={addHttps(playlists.spotify)}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className={`image`}>
                <img src={Spotify} alt="" />
              </div>
              <span className="text">Spotify</span>
            </a>
          )}
          {playlists.deezer && (
            <a
              href={addHttps(playlists.deezer)}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className={`image`}>
                <img src={Deezer} alt="" />
              </div>
              <span className="text">Deezer</span>
            </a>
          )}
          {playlists.youtube && (
            <a
              href={addHttps(playlists.youtube)}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className={`image`}>
                <img src={Youtube} alt="" />
              </div>
              <span className="text">YouTube</span>
            </a>
          )}
        </div>
      </div>
    </PlayListWrapper>
  );
};

PlayList.propTypes = {};

export default PlayList;
