import React, { useState, useMemo, useCallback } from "react";
import Immutable from 'seamless-immutable';

const createObject = (keys, value) => {
  if (keys.length === 0) {
    return value;
  }
  const result = {};
  let obj = result;
  const myKeys = [...keys];
  const lastKey = myKeys.pop();
  for (const key of myKeys) {
    obj[key] = {};
    obj = obj[key];
  }
  obj[lastKey] = value;
  return result;
}

const mySetState = (state, keys, value, conditionCallback) => {
  return state => {
    if (conditionCallback === null || conditionCallback(state)) {
      if (keys.length === 0) {
        return Immutable(value);
      } else if (state !== Object(state)) {
        return Immutable(createObject(keys, value));
      } else {
        return state.setIn(keys, value);
      }
    } else {
      return state;
    }
  }
}

const ContextProvider = props => {
  const ContextWrapper = props.context.Provider;
  const [state, setState] = useState(Immutable(props.value));

  const setElement = useCallback((...args) => {
    let conditionCallback = null;
    if (typeof args[0] === 'function') {
      conditionCallback = args[0];
      args.shift(0);
    };
    const value = args.pop();
    const path = args.pop() || null;
    const keys = path === null ? [] : path.split('.');
    setState(mySetState(state, keys, value, conditionCallback));
  }, [state]);

  const usePath = useCallback(path => {
    const elements = path === undefined ? [] : path.split('.');
    const setInnerElement = (...args) => {
      let conditionCallback = null;
      if (typeof args[0] === 'function') {
        conditionCallback = args[0];
        args.shift(0);
      }
      const value = args.pop();
      const innerPath = args.pop() || null;
      const innerElements = innerPath === null ? [] : innerPath.split('.');
      const keys = [...elements, ...innerElements].filter(x => x !== "");
      setState(mySetState(state, keys, value, conditionCallback));
    };

    return [
      state === Object(state) && elements.length > 0 ? state.getIn(elements) : state,
      setInnerElement
    ]
  }, [state]);

  const value = useMemo(() => {return { state, setState, setElement, usePath }}, [state]);

  return (
    <ContextWrapper value={{ state, setState, setElement, usePath }}>
      {props.children}
    </ContextWrapper>
  );
};

export default ContextProvider;
