import styled from 'styled-components';

const ButtonFooterWrapper = styled.div`
  text-align: center;

  .link {
    display: flex;
    background: white;
    padding: 7px 15px;
    border-radius: 50px;
    color: ${props => props.theme.color.primaryColor}

    &:hover {
      text-decoration: none;
    }
  }
`;

export default ButtonFooterWrapper;
