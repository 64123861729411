import {getCookie} from './get-cookie';
import {COOKIE_UNSET} from 'ToolboxParams/cookies';

export default function getCookiePopin() {
  const cookie = getCookie("chuchoteurs" + window.location.hostname + "Popin");
  if (cookie !== "") {
    return false;
  } else {
    return true;
  }
}
