import getCurrentPositionPromise from './get-current-position-promise';
import timeoutPromise from '../timeout-promise';

// throws if geolocation is unavailable (error = undefined) or fails (error = null)
// returns :
// - an object with coords.latitude and coords.longitude

export default async function getCurrentPosition() {
  const options = {
    enableHighAccuracy: true,
    maximumAge: 30000,
    timeout: 5000
  };

  const locate = async () => {
    try {
      const position = await getCurrentPositionPromise(options);
      return position;
    } catch (err) {
      options.enableHighAccuracy = false;
      options.timeout = 12000;
      const position = await getCurrentPositionPromise(options);
      return position;
    }
  }

  return timeoutPromise({delay: 17000, error: null, promises: [locate()]});
}
