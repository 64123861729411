//returns itinerairy url to specified map, defaults to google maps
export default function directions({
  service = "google-maps",
  platform = "android",
  origin = {},
  destination = {},
  travelMode = "driving",
  zoom = 15
}) {
  let transportation; //travelMode option

  switch (service) {
    case "via-michelin":
      switch (travelMode) {
        case "bicycling":
          transportation = 3;
          break;
        case "walking":
          transportation = 2;
          break;
        default:
          transportation = 0;
      }

      if (destination.address) {
        return `https://www.viamichelin.fr/web/Itineraires?departure=${
          origin.zipCode
        }%20${origin.city}&arrival=${destination.address.replace(
          /\s/g,
          "%20"
        )}%20${destination.zipCode}%20${destination.city.replace(
          /\s/g,
          "%20"
        )}&vehicle=${transportation}`;
      } else {
        return `https://www.viamichelin.fr/web/Itineraires`;
      }

    case "waze":
      if (platform === "ios" || platform === "android") {
        return `https://www.waze.com/ul?ll=${destination.latitude}%2C${
          destination.longitude
        }&navigate=yes`;
      } else {
        return `https://www.waze.com/livemap?at=now&from_lat=${
          origin.latitude
        }&from_lon=${origin.longitude}&to_lat=${destination.latitude}&to_lon=${
          destination.longitude
        }`;
      }

    case "mappy": //mappy has no API... so this is a hack!
      //mappy possible options are: voiture, train, covoiturage, motorbike, autocar
      switch (travelMode) {
        case "bicycling":
          transportation = "motorbike";
          break;
        case "transit":
          transportation = "autocar";
          break;
        case "walking":
          transportation = "pieton";
          break;
        default:
          transportation = "voiture";
      }
      return `https://fr.mappy.com/#/TItinerary/IFR${origin.latitude}%20${
        origin.longitude
      }|TO${destination.latitude}%20${
        destination.longitude
      }|MO${transportation}/N`;

    case "apple-maps": //https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html & https://stackoverflow.com/questions/32580887/example-of-apple-maps-url-to-find-the-directions-to-certain-coordinates
      switch (travelMode) {
        case "bicycling":
          transportation = "w";
          break;
        case "transit":
          transportation = "r";
          break;
        case "walking":
          transportation = "w";
          break;
        default:
          transportation = "d";
      }
      return `https://maps.apple.com/?saddr=${origin.latitude},${
        origin.longitude
      }&daddr=${destination.latitude},${
        destination.longitude
      }&dirflg=${transportation}&z=${zoom}`;

    default:
      //google maps
      return `https://www.google.com/maps/dir/?api=1&origin=${
        origin.latitude
      },${origin.longitude}&destination=${destination.latitude},${
        destination.longitude
      }&travelmode=${travelMode}&zoom=${zoom}`;
  }
}
