//import React and PropTypes dependencies
import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

//import Context
import { WebappContext } from 'ToolboxUtils/web/context/context';

//import style and icons
import SliderWrapper from './slider.style';
import ArrowLeft from 'ToolboxComponents/commons/sliders/slider/ui/assets/arrow-left'; //the left arrow icon
import ArrowRight from 'ToolboxComponents/commons/sliders/slider/ui/assets/arrow-right'; // the right arrow icon

//import SwiperJS
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

//import utils
import counterIncrementor from 'ToolboxUtils/web/counters/counter-incrementor'; //increment by 1 any given number
import isMobileView, {
  isMobileBreakpoint,
} from 'ToolboxUtils/web/is-mobile-view'; //detect if screen is mobile, int breakpoint

//initialize the counterSlider
//this counter gives a unique ID to every sliders to avoid errors when there are multiple sliders on the same view
let counterSlider = 0; //give a unique ID to every sliders

//main function
const Slider = props => {
  //setup components variables
  //increament the counterSlider
  counterSlider = counterIncrementor(counterSlider); //update slider's counter

  //init the context
  const webappContext = useContext(WebappContext);

  //init the ids to give in the slider
  const sliderId = `swiper-${counterSlider}`; //flesh out the slider's ID
  const paginationId = `${sliderId}-pagination`;
  const arrowPrevId = `${sliderId}-arrow-prev`;
  const arrowNextId = `${sliderId}-arrow-next`;

  //update the slider's default mobile and desktop props, here the default props are being overwritten by the props given to the component
  const computedMobile = { ...props.defaultMobile, ...props.mobile }; //actual slider's mobile options
  const computedDesktop = { ...props.defaultDesktop, ...props.desktop }; // actual slider's desktop options
  if (props.desktop.test) {
    console.log(props.desktop);
    console.log(computedDesktop);
  }
  //slider specific variables
  const [swiperRef, setSwiperRef] = useState(null); //used to wait for the slider to be initialized before sliding to specific slide
  const [swiperWrapperHeight, setSwiperWrapperHeight] = useState(0); //I forgot what it's for...
  const sliderRef = useRef();                                        //idem
  const swiperWrapperRef = useRef();                                 //idem
  const isNavigationCouldShowMobile = //automatically decides if the navigation arrows should show on mobile
    props.children.length > computedMobile.slidesPerView ? true : false;
  const isNavigationCouldShowDesktop = //automatically decides if the navigation arrows should show on desktop
    props.children.length > computedDesktop.slidesPerView ? true : false;
  const [isTextEdit, setIsTextEdit] = useState(false); //needed to make text editable with the quill module (Text component)

  //set swiping behavior based on the groupSliding boolean contained in the mobile and desktop props
  /*automatically make slider slide all visible slides at every click*/
  if (computedMobile.groupSliding === true) {
    computedMobile.slidesPerGroup = computedMobile.slidesPerView;
  }
  if (computedDesktop.groupSliding === true) {
    computedDesktop.slidesPerGroup = computedDesktop.slidesPerView;
  }

  //detect resolution (mobile or not)
  const [isMobile, setIsMobile] = useState(null); //set if screen is mobile
  // on init determine if screen is mobile or desktop
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  //detect if text should be editable with the quill plugin or not
  useEffect(() => {
    if (webappContext !== undefined) {
      setIsTextEdit(webappContext.state.edition.activated);
    }
  }, [webappContext]);

  //init slider
  useEffect(() => {
    const breakpoint = isMobileBreakpoint; // setup the breakpoint
    let swiper = null;
    swiper = new Swiper(`#${sliderId}`, {
      //used for the arrowsInside props to recalculate the size of sildes
      observer: true, // auto update in case a child is modified
      observeParents: true, // watch the modification of the slider's parent element
      allowTouchMove: !isTextEdit, // used when editing with react-quill
      preventClicks: !isTextEdit, // used when editing with react-quill
      preventClicksPropagation: !isTextEdit, // used when editing with react-quill
      ...(props.general || {}),
      //event listener
      on: {
        slideChange: () => {props.getCurrentSlideIndex(swiper ? swiper.activeIndex : 0); props.getRealCurrentSlideIndex && props.getRealCurrentSlideIndex(swiper ? swiper.realIndex : 0)},
        ...(props.general ? props.general.on : {})
      },
      ...computedMobile,
      navigation: {
        nextEl: `#${arrowNextId}`,
        prevEl: `#${arrowPrevId}`,
      },
      pagination: {
        el: `#${paginationId}`,
        dynamicBullets: computedMobile.dynamicBullets,
        dynamicMainBullets: computedMobile.dynamicMainBullets,
        clickable: true,
      },
      breakpoints: {
        [breakpoint]: {
          ...computedDesktop,
          pagination: {
            dynamicBullets: computedDesktop.dynamicBullets,
            dynamicMainBullets: computedDesktop.dynamicMainBullets,
          },
        },
      },
    });
    if (props.setSwiper) {
      props.setSwiper(swiper);
    }
    setSwiperRef(swiper);

    return () => swiper.destroy();
    // eslint-disable-next-line
  }, [isTextEdit]);

  //if slideTo is set, then slide to indicated slide
  useEffect(() => {
    if (swiperRef !== null && props.slideTo !== null) {
      try {
        swiperRef.slideTo(props.slideTo);
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line
  }, [swiperRef, props.slideTo]);

  // eslint-disable-next-line
  useEffect(() => {
    const swiperWrapperCurrentHeight = swiperWrapperRef.current.clientHeight;
    if (swiperWrapperCurrentHeight > swiperWrapperHeight) {
      setSwiperWrapperHeight(swiperWrapperCurrentHeight);
    }
  });

  return (
    <SliderWrapper
      ref={sliderRef}
      {...props}
      isMobile={isMobile}
      computedMobile={computedMobile}
      computedDesktop={computedDesktop}
      isNavigationCouldShowMobile={isNavigationCouldShowMobile}
      isNavigationCouldShowDesktop={isNavigationCouldShowDesktop}
      style={props.style}
      swiperWrapperHeight={swiperWrapperHeight}
    >
      {/*<!-- Slider main container -->*/}
      <div id={sliderId} className="swiper-container">
        {/*<!-- Additional required wrapper -->*/}
        <div
          className="swiper-wrapper"
          onClick={event => {
            props.handleClick(true);
          }}
          ref={swiperWrapperRef}
        >
          {/*<!-- Slides -->*/}
          {props.children.length > 1 ? (
            props.children.map((child, index) => (
              <div key={`swiper-slide-${index}`} className="swiper-slide">
                {child}
              </div>
            ))
          ) : (
            <div key={`swiper-slide-1}`} className="swiper-slide">
              {props.children}
            </div>
          )}
        </div>
        {/*<!-- If we need pagination -->*/}
        <div
          id={paginationId}
          className="swiper-pagination swiper-pagination-design"
        />

        {/*<!-- If we need scrollbar -->*/}
        {/*<div className="swiper-scrollbar" />*/}
      </div>
      {/*<!-- If we need navigation buttons -->*/}
      <div id={arrowPrevId} className="swiper-button swiper-button-prev-design">
        <ArrowLeft width={isMobile ? computedMobile.arrowsWidth : computedDesktop.arrowsWidth} height={isMobile ? computedMobile.arrowsHeight : computedDesktop.arrowsHeight} fill={props.arrowsColor} />
      </div>
      <div id={arrowNextId} className="swiper-button swiper-button-next-design">
        <ArrowRight width={isMobile ? computedMobile.arrowsWidth : computedDesktop.arrowsWidth} height={isMobile ? computedMobile.arrowsHeight : computedDesktop.arrowsHeight} fill={props.arrowsColor} />
      </div>
    </SliderWrapper>
  );
};

Slider.defaultProps = {
  //default props for the slider init
  defaultMobile: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    dots: false,
    dotsInside: false,
    arrows: false,
    arrowsInside: false,
    arrowsMiddle: false,
    arrowsTop: '',
    arrowsWidth: 40,
    arrowsHeight: 40,
    arrowsOffset: 10,
    arrowsDisabledHide: false,
    speed: 300,
    freeMode: false,
    dynamicBullets: true,
    dynamicMainBullets: 5,
    spaceBetween: 0,
    slidesPadding: 0,
    slidesPaddingBottom: 0,
    slidersPerColumn: 1,
    watchOverflow: true,
    sideFade: false,
    centerInsufficientSlides: false,
    groupSliding: false, //all slides slide on arrow click, set to false if 'previousElementSibling' of undefined error
  },
  defaultDesktop: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    dots: false,
    dotsInside: false,
    arrows: false,
    arrowsInside: false,
    arrowsMiddle: false,
    arrowsTop: '',
    arrowsWidth: 40,
    arrowsHeight: 40,
    arrowsOffset: 10,
    arrowsDisabledHide: false,
    speed: 300,
    freeMode: false,
    dynamicBullets: true,
    dynamicMainBullets: 5,
    spaceBetween: 0,
    slidesPadding: 0,
    slidesPaddingBottom: 0,
    slidersPerColumn: 1,
    watchOverflow: true,
    sideFade: false,
    centerInsufficientSlides: false,
    groupSliding: false, //all slides slide on arrow click, set to false if 'previousElementSibling' of undefined error
  },
  mobile: {},
  desktop: {},
  height: '100%',
  width: '100%',
  dotsColor: '#999',
  dotsColorActive: '#333',
  dotsSize: 20,
  arrowsColor: '#555',
  arrowsBackground: '#fff',
  arrowsShadow: true,
  handleClick: () => {},
  getCurrentSlideIndex: () => {},
  slideTo: 0,
  textEdit: false,
};

Slider.propTypes = {
  mobile: PropTypes.object, //mobile options
  desktop: PropTypes.object, //desktop options
  height: PropTypes.string, //slider height
  width: PropTypes.string, //slider width
  dotsColor: PropTypes.string, //default dot color
  dotsColorActive: PropTypes.string, //active dot color
  dotsSize: PropTypes.number, //control dots size
  arrowsColor: PropTypes.string, //arrow icon color
  arrowsBackground: PropTypes.string, //arrow icon background
  arrowsShadow: PropTypes.bool, //arrow display shadow
  handleClick: PropTypes.func, //onClick function
  getCurrentSlideIndex: PropTypes.func, //returns active slide index
  slidesPadding: PropTypes.number, //add space between the slider frame and the slides, useful for cut-off box-shadows
  slideTo: PropTypes.number, //select the slide by index
  textEdit: PropTypes.bool, //true when editing the react-quill texts
};

export default Slider;
