import React, { useContext } from 'react';
import PropTypes from "prop-types";
import formatAddress from 'ToolboxUtils/web/formats/address';
import NearestOtBlock from './nearest-ot-block.style';
import DirectionsLink from 'ToolboxComponents/commons/links/directions-link/directions-link';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import { ThemeContext,LanguageContext, WebappContext } from "ToolboxUtils/web/context/context";

const NearestOt = ({ot}) => {
    const [language] = useContext(LanguageContext).usePath();
    const [analytics] = useContext(WebappContext).usePath('analytics');
    const theme = useContext(ThemeContext).state;
    
  
    return (
      <NearestOtBlock className="container nearest-ot" ot= {ot} theme={theme}>
       {ot.address1 !== undefined && ot.address1 !== null && ot.address1 !== '' && (
        <DirectionsLink className={`row justify-content-center block`} product={ot}  onClick={() => {analytics.sendAddressContactEvent(ot)}}>
            <ImageResize src={ot.photo} />
            <br/>
            <div className='concierge-name'>{ot.name}</div>
          <div className="info address">
            <div className="text-plus-proche">
              {{
                fr: <span>Le plus proche de vous</span>,
                en: <span>Nearest tourism information point:</span>
              }[language]}
            </div>
            <br/>
            <div className='adresse'>{ot.address1}<br/>{ot.city}</div>
          </div>
          <div className='lien-iti'><span className="icon adn adn-directions"></span>{{
              fr: <span>y aller</span>,
              en: <span>go</span>
            }[language]}</div>
        </DirectionsLink>
      )}
      </NearestOtBlock>
    );
  };
  
NearestOt.propTypes ={};

  
export default NearestOt;
  