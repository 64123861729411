import React from 'react';

const ArrowLeft = props => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || "40"} height={props.height || "40"} viewBox="0 0 24 24"><path fill={props.fill} d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/><path fill="none" d="M0 0h24v24H0V0z"/></svg>
  );
};

export default ArrowLeft;
