import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const NearestOtBlock = styled.div`
.block {
  display: block;
  text-align:center;
  align-items: center;
  padding: 10px 0;
  &:hover{
      color:white;
      text-decoration:none;
  }
}
.concierge-name{
    color:white;
    font-size:16px;
    text-align: center;
}
.info{
    text-align:center;
    align-items:center;
    justify-content:center;
    color: white;
    font-size:16px;
    padding-bottom:35px;
    span{
        color: ${props => props.theme.color.primaryColor};
        font-size:18px;
    }
    .adresse{
        padding-top:6px;
    }

}
height: 100%;
margin-left: 10px;

img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 80px;
    width: 160px;
    height: 160px;
    border: 5px solid ${props => props.theme.color.primaryColor};
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
}

@media (min-width: ${isMobileBreakpoint}px) {
    img {
      width: 150px;
      height: 150px;
      border-radius: 75px;
    }
    .concierge-name{
        font-size:27px;
    }
    .info{
        span{
            color: ${props => props.theme.color.tertiaryColor};
        }
    }

}


.address {
  font-size: ${props => `${14*props.theme.font.primaryFont.scaling/100}px`};
  line-height: 17px;
}

.lien-iti{
    display:inline-flex;
    border: 1px solid white;
    border-radius:20px;
    align-items:center;
    justify-content:center;
    color:white;
    width:132px;
    .icon {
        display: flex;
        flex: 0 0 auto;
        color:white;
        margin-right: 20px;
        font-size: 36px;
        &:hover{
            color:white;
            
        }
    }
}

`;

export default NearestOtBlock;

