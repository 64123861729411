const themeContext = {
  color: {
    primaryColor: '#d86397',
    secondaryColor: '#404040',
    tertiaryColor: '#dcdcdc',
    quaternaryColor: '#7c787a',
    neutralColor: '#8E44AD',
  },
  font: {
    primaryFont: {
      name: 'Poppins',
      url: null,
      options: ':400,700',
    },
    secondaryFont: {
      name: 'Atma',
      url: null,
      options: ':400',
    },
    tertiaryFont: {
      name: 'tertiaryFont',
      url: 'http://localhost:8000/fonts/Roboto-Regular.ttf',
      options: null,
    },
  },
};

export default themeContext;
