import {getCookie} from './get-cookie';

export default function getLanguage(cookieName) {
  const cookieLang = getCookie(cookieName);
  if (cookieLang !== "") {
    return cookieLang;
  }

  const lang = window.navigator.userLanguage || window.navigator.language;

  if (lang === undefined) {
    return 'fr';
  }
  const simpleLang = lang.split('-')[0];

  return simpleLang;
}
