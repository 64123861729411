import {useState, useEffect} from 'react';
import isEqual from 'lodash.isequal';

const useApiCore = (api, method, url, options = {}) => {
  // remove onComplete from args because on each render onComplete will be a new function
  // so the hook will cause infinite renders
  const [symbol, setSymbol] = useState(Symbol());
  const [args, setArgs] = useState({url, options: {...options, method, onComplete: undefined}});
  const [functionArgs, setFunctionArgs] = useState({url, options: {...options, method, onComplete: undefined}});
  const [initialParams] = useState(options.noTrack ? {...options.params} : null);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isHttpError, setIsHttpError] = useState(false);
  const [error, setError] = useState(false);

  // on each render, newArgs is updated
  const newArgs = {url, options: {...options, method, onComplete: undefined}};

  if (options.noTrack) {
    // no arg is tracked
    if (options.noTrack === true) {
      delete functionArgs.options;
      delete newArgs.options;
      delete functionArgs.url;
      delete newArgs.url;
      if (!isEqual(functionArgs, newArgs)) {
        //newArgs.options.params = {...newArgs.options.params, ...savedParams};
        setFunctionArgs(newArgs);
        setArgs(newArgs);
      }
    } else {
      // cloning params objects so that modification of params does not affect args params
      newArgs.options.params = {...newArgs.options.params};
      functionArgs.options.params = {...functionArgs.options.params};
      const savedParams = {};
      for (const prop of options.noTrack) {
        savedParams[prop] = initialParams[prop];
        delete functionArgs.options.params[prop];
        delete newArgs.options.params[prop];
      }
      if (!isEqual(functionArgs, newArgs)) {
        newArgs.options.params = {...newArgs.options.params, ...savedParams};
        setFunctionArgs(newArgs);
        setArgs(newArgs);
      } else {
        functionArgs.options.params = {...functionArgs.options.params, ...savedParams};
        newArgs.options.params = {...newArgs.options.params, ...savedParams};
      }
    }
  } else {
    if (!isEqual(functionArgs, newArgs)) {
      setFunctionArgs(newArgs);
      setArgs(newArgs);
    }
  }

  useEffect(() => {
    if (args.options.depends === false) {
      return;
    }
    const fetchData = async() => {
      setIsError(false);
      setIsLoading(true);
      let data = null;
      try {
        data = await api.fetch(args.url, {...args.options, key: symbol});
      } catch (error) {
        if (error.isHttpError) {
          setIsHttpError(true);
        } else {
          setIsError(true);
        }
        setError(error);
        setIsComplete(true);
        setIsLoading(false);
        if (options.onComplete) {
          options.onComplete({error, isLoading: false, isComplete: true, isError: !error.isHttpError, isHttpError: error.isHttpError});
        }
        // TODO: send error to datawarehouse

        // return so that next instructions are not executed
        return;
      }
      // don't catch
      setData(data);
      setIsComplete(true);
      setIsLoading(false);
      if (options.onComplete) {
        options.onComplete({data, isLoading: false, isComplete: true, isError:false, isHttpError: false});
      }
    }
    fetchData();
  }, [args]);

  return {
    data,
    isLoading,
    isComplete,
    isHttpError,
    isError,
    error,
    get: (url, options) => setArgs({url, options: {...options, method: 'get'}}),
    post: (url, options) => setArgs({url, options: {...options, method: 'post'}}),
    put: (url, options) => setArgs({url, options: {...options, method: 'put'}})
  };
}


export default useApiCore;
