import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import FooterSliderCard from 'ToolboxComponents/commons/cards/footer-slider-card/footer-slider-card';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const SliderFooterWrapper = styled.div`
  .mobile {
    display: flex;
    flex-flow: wrap;
    justify-content:center;
    margin-left:0;
    margin-right:0;
    .expert{
      padding-left:0;
      padding-right:0;
    }
  }

  .desktop {
    display: none;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    height: 100%;

    .mobile {
      display: none;
    }

    .desktop {
      display: flex;
      flex-flow: wrap;
    }
  }
`;

const SliderFooter = ({experts}) => {
  return (
    <SliderFooterWrapper className="slider-footer">
      <div className="row mobile">
          {experts.map((expert, index) => (
             <div className='col-6 expert'> <FooterSliderCard key={index} photo={expert.photo} nom={expert.firstName} teaser={expert.text} /> </div>
            ))}
      </div>
      <div className="row desktop">
        {experts.map((expert, index) => {
          return <div className='col-6'><FooterSliderCard key={index} photo={expert.photo} nom={expert.firstName} teaser={expert.text} /></div>
        })}
      </div>
    </SliderFooterWrapper>
  );
};

SliderFooter.propTypes = {
  experts: PropTypes.array.isRequired,
};

export default SliderFooter;
