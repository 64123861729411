import styled from "styled-components";

export const isMobileBreakpoint = 850;

const IsMobileViewWrapper = styled.div.attrs({ id: "is-mobile-view" })`
  position: absolute;

  @media (min-width: ${isMobileBreakpoint}px) {
    visibility: hidden;
  }
`;

export default IsMobileViewWrapper;
