import React, { useContext } from 'react';
import { ThemeContext, LanguageContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
// import PropTypes from "prop-types";
import TitleWrapper from './title.style';
import Text from 'ToolboxComponents/commons/texts/text/text';


const Title = props => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const whiteLabel = useContext(WhiteLabelContext).state;

  return (
    <TitleWrapper theme={theme} className="title" {...props}>
      <Text className="main" path='page.footer.expertsTitle' />
      {props.displayedSchedule && props.displayedSchedule !== "" && <div className="sub">{props.displayedSchedule}</div>}
    </TitleWrapper>
  );
};

Title.propTypes = {};

export default Title;
