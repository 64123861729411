import React from 'react';


export default {
  'public-profile': {
    code: 'public',
    route: '/:id',
    name: {
      fr: 'Profil public'
    },
    Component: React.lazy(() => import('App/root/public-profile/public-profile')),
  },
  search: {
    code: 'search',
    route: '',
    name: {
      fr: 'Recherche'
    },
    Component: React.lazy(() => import('App/root/search/search')),
  },
  results: {
    code: 'results',
    route: '',
    name: {
      fr: 'Résultats'
    },
    Component: React.lazy(() => import('App/root/results/results')),
    isFixedHeader: true,
    footer: null,
  },
  product: {
    code: 'product',
    route: '/:id(\\d+)/*',
    name: {
      fr: 'Produit'
    },
    Component: React.lazy(() => import('App/root/product/product')),
  },
  concierge: {
    code: 'concierge',
    route: '',
    name: {
      fr: 'Conciergerie'
    },
    Component: React.lazy(() => import('App/root/concierge/concierge')),
    footer: null,
  },
  'account-confirm': {
    code: 'confirm',
    route: '/:token',
    name: {
      fr: 'Confirmation de compte'
    },
    Component: React.lazy(() => import('App/root/account-confirm/account-confirm')),
  },
  account: {
    code: 'account',
    route: '',
    name: {
      fr: 'Compte'
    },
    Component: React.lazy(() => import('App/root/account/account')),
  },
  'reset-password': {
    code: 'reset',
    route: '/:token',
    name: {
      fr: 'Réinitialisation de mot de passe'
    },
    Component: React.lazy(() => import('App/root/reset-password/reset-password')),
  },
  collection: {
    code: 'collection',
    route: '',
    name: {
      fr: 'Pépites'
    },
    Component: React.lazy(() => import('App/root/collections/collections')),
  },
  cookies: {
    code: 'cookies',
    route: '',
    name: {
      fr: 'Cookies'
    },
    Component: React.lazy(() => import('App/root/cookies/cookies')),
  },
  cgu: {
    code: 'cgu',
    route: '',
    name: {
      fr: 'CGU'
    },
    Component: React.lazy(() => import('App/root/cgu/cgu')),
  },
  cgv: {
    code: 'cgv',
    route: '',
    name: {
      fr: 'CGV'
    },
    Component: React.lazy(() => import('App/root/cgv/cgv')),
  },
  privacy: {
    code: 'privacy',
    route: '',
    name: {
      fr: 'Données privées'
    },
    Component: React.lazy(() => import('App/root/privacy/privacy')),
  },
  legals: {
    code: 'legals',
    route: '',
    name: {
      fr: 'Mentions Légales'
    },
    Component: React.lazy(() => import('App/root/legals/legals')),
  },
  home: {
    code: 'home',
    route: '',
    name: {
      fr: 'Accueil'
    },
    Component: React.lazy(() => import('App/root/home/home')),
  },
  catalog: {
    code: 'catalog',
    route: '',
    name: {
      fr: 'Catalogue'
    },
    Component: React.lazy(() => import('App/root/catalogs/catalogs')),
  },
  whisperer: {
    code: 'whisperers',
    route: '',
    name: {
      fr: 'Chuchoteurs'
    },
    Component: React.lazy(() => import('App/root/whisperers/whisperers')),
    footer: null,
  },
  'circuits-choice': {
    code: 'circuits-choice',
    route: '',
    name: {
      fr: 'Sélection du Parcours'
    },
    Component: React.lazy(() => import('App/root/circuits-choice/circuits-choice')),
  },
  circuit: {
    code: 'circuit',
    route: '/:id(\\d+)/*',
    name: {
      fr: 'Parcours'
    },
    Component: React.lazy(() => import('App/root/circuit/circuit')),
  },
  clusters: {
    code: 'clusters',
    route: '',
    name: {
      fr: 'Virées'
    },
    Component: React.lazy(() => import('App/root/clusters/clusters')),
  },
  cluster: {
    code: 'cluster',
    route: '/:id(\\d+)/*',
    name: {
      fr: 'Virée'
    },
    Component: React.lazy(() => import('App/root/cluster/cluster')),
  },
  destination: {
    code: 'destination',
    route: '',
    name: {
      fr: 'Destination'
    },
    Component: React.lazy(() => import('App/root/destination/destination')),
  },
  clustersBiennale: {
    code: 'clustersBiennale',
    route: '',
    name: {
      fr: 'Parcours Biennale'
    },
    Component: React.lazy(() => import('App/root/clusters-biennale/clusters-biennale')),
  },
  'tourisme-durable': {
    code: 'tourisme-durable',
    route: '',
    name: {
      fr: 'Tourisme Durable'
    },
    Component: React.lazy(() => import('App/root/tourisme-durable/tourisme-durable')),
  },
  incontournable: {
    code: 'incontournable',
    route: '',
    name: {
      fr: 'Incontournables'
    },
    Component: React.lazy(() => import('App/root/incontournable/incontournable')),
  },
  'val-idees': {
    code: 'val-idees',
    route: '',
    name: {
      fr: 'Val idées'
    },
    Component: React.lazy(() => import('App/root/val-idees/val-idees')),
  },
  'rendez-vous': {
    code: 'rendez-vous',
    route: '',
    name: {
      fr: 'Rendez-vous'
    },
    Component: React.lazy(() => import('App/root/rendez-vous/rendez-vous')),
  },
  heartstoppers: {
    code: 'heartstoppers',
    route: '/:id',
    name: {
      fr: 'Coups de coeur'
    },
    Component: React.lazy(() => import('App/root/heartstoppers/heartstoppers')),
  },
  "heartstoppers-choice": {
    code: 'heartstoppers-choice',
    route: '',
    name: {
      fr: 'Coups de coeur'
    },
    Component: React.lazy(() => import('App/root/heartstoppers-choice/heartstoppers-choice')),
  },
  "majors-choice": {
    code: 'majors-choice',
    route: '',
    name: {
      fr: 'Incontournables'
    },
    Component: React.lazy(() => import('App/root/majors-choice/majors-choice')),
  },
};
