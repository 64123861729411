import React, { useState, useEffect, useContext } from 'react';


const NotFound = () => {
  return (
    <div></div>
  );
}

export default NotFound;
