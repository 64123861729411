import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import { BannerWrapper } from 'ToolboxComponents/commons/banner/banner.styles';

const Banner = props => {
  const theme = useContext(ThemeContext).state;
  return (
    <BannerWrapper theme={theme} background={props.background} isFixed={props.isFixed} top={props.top} id={props.bannerName}>
      {props.text}
      {props.onClose &&
        <button type='button' onClick={() => props.onClose()}>
          <span className='adn adn-close'></span>
        </button>
      }
    </BannerWrapper>
  )
};

Banner.propTypes = {
  background: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isFixed: PropTypes.bool,
  top: PropTypes.string,
  onClose: PropTypes.func,
  bannerName: PropTypes.string.isRequired,
}

export default Banner;
