import styled from "styled-components";

const ContactWrapper = styled.div`
  .block {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  .icon {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background:white ;
    color:${props => props.theme.color.primaryColor};
    border-radius: 25px;
    margin-right: 20px;
    font-size: 36px;
  }

  .address {
    font-size: ${props => `${14*props.theme.font.primaryFont.scaling/100}px`};
    line-height: 17px;
  }

  a {
    color: #212529;
    text-decoration: none;
  }
  .info{
    color: white;
  }
`;

export default ContactWrapper;
